import Vue from 'vue';
import Router from 'vue-router';

import LoginPage from '@/login/LoginPage';
import ESHAPEMapViewEL from '../components/ESHAPEMapViewEL.vue';
import ESHAPEMapView from '../components/ESHAPEMapView.vue';
import DamageAssessment from '../components/DamageAssessment.vue';
import HighLevelMonitoring from '../components/HighLevelMonitoring.vue';

Vue.use(Router);

export const router = new Router({
    mode: 'history',
    routes: [
        { 
            path: '/en', 
            component: ESHAPEMapView, 
            meta: { authorize: [] } 
        },
        { 
            path: '/el', 
            component: ESHAPEMapViewEL, 
            meta: { authorize: [] } 
        },
        { 
            path: '/damage', 
            component: DamageAssessment, 
            meta: { authorize: [] } 
        },
        { path: '/highlevel', component: HighLevelMonitoring },
        { 
            path: '/login', 
            component: LoginPage 
        },
        // otherwise redirect to home
        { path: '*', redirect: '/en' }
    ]
});

export default router
