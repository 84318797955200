<template>
<div></div>
</template>

<script>
export default {
	name: "Requests",
	components: {},
	methods: {

		fetchByTileAndDateRangeWMSInfo: function(tileList, dateStart, dateEnd) {
			//{"action":"tiledaterangewmsinfo", "properties":{"tile":"35TLF","date_start":"2020-10-05 00:00:00", "date_end":"2020-11-05 00:00:00"}}
			let postParams = {};
			let tileListPost = [];
			tileList.forEach( (el) => {
				tileListPost.push(el.id);
			});

			postParams["action"] = "tiledaterangewmsinfo";
			postParams["properties"] = {}
			postParams["properties"]["tile_list"] = tileListPost;
			postParams["properties"]["date_start"] = dateStart;
			postParams["properties"]["date_end"] = dateEnd;
			return this.$http.post(this.$store.state.serviceEndPoint, postParams);
		}
		,fetchLatestTileWMS: function () {
			let postParams = {};
			postParams["action"]="wmsinfo";
			return this.$http.post(this.$store.state.serviceEndPoint, postParams);	
		}
		,fetchPolygonNDVI: function(polygon, dateStart, dateEnd) {
			let postParams = {};
			postParams["action"] = "polygonndvi";
			postParams["properties"] = {}
			postParams["properties"]["parcel_id"] = polygon;
			postParams["properties"]["date_start"] = dateStart;
			postParams["properties"]["date_end"] = dateEnd;
			return this.$http.post(this.$store.state.serviceEndPoint, postParams);
		}
		,fetchPolygonNDVIAndDailyWeatherData: function(polygon, dateStart, dateEnd) {
			let postParams = {};
			postParams["action"] = "polygonndvianddailyweatherdata";
			postParams["properties"] = {}
			postParams["properties"]["parcel_id"] = polygon;
			postParams["properties"]["date_start"] = dateStart;
			postParams["properties"]["date_end"] = dateEnd;
			return this.$http.post(this.$store.state.serviceEndPoint, postParams);
		}	
		,fetchPolygonsHighLevel: function() {
			let postParams = {};
			postParams["action"] = "highlevelmonitoringperfectures";
			return this.$http.post(this.$store.state.serviceEndPoint, postParams);
		}	
		,fetchPolygons: function() {
			let postParams = {};
			postParams["action"]="polygons";
			return this.$http.post(this.$store.state.serviceEndPoint, postParams);		
		}
		,fetchPolygonStyle: function(figr_ids) {
			let postParams = {};
			postParams["action"] = "polygonstyle";
			postParams["properties"] = {}
			postParams["properties"]["figr_ids"] = figr_ids;
			return this.$http.post(this.$store.state.serviceEndPoint, postParams);
		}
		,fetchDailyWeatherData: function(weatherStationId, dateStart, dateEnd) {
			let postParams = {};
			postParams["action"] = "dailyweatherdata";
			postParams["properties"] = {}
			postParams["properties"]["weather_station_id"] = weatherStationId;
			postParams["properties"]["date_start"] = dateStart;
			postParams["properties"]["date_end"] = dateEnd;
			return this.$http.post(this.$store.state.serviceEndPoint, postParams);
		}
		,fetchHourlyWeatherData: function(weatherStationId, dateStart, dateEnd) {
			let postParams = {};
			postParams["action"] = "hourlyweatherdata";
			postParams["properties"] = {}
			postParams["properties"]["weather_station_id"] = weatherStationId;
			postParams["properties"]["date_start"] = dateStart;
			postParams["properties"]["date_end"] = dateEnd;
			return this.$http.post(this.$store.state.serviceEndPoint, postParams);
		}
		,fetchDamagePolygons: function(crop, eventId) {
			let postParams = {};
			postParams["action"] = "damagepolygons";
			postParams["properties"] = {}
			if (eventId == null) {
				postParams["properties"]["event_id"] = 21;
			} else {
			postParams["properties"]["event_id"] = eventId;}
			postParams["properties"]["crop"] = crop;
			return this.$http.post(this.$store.state.serviceEndPoint, postParams);
		}
		,login: function(username, password) {
			let postParams = new FormData();
			postParams.append("login_user", true);
			postParams.append("username", username);
			postParams.append("password", password);
			return this.$http.post(this.$store.state.userEndPoint, postParams);
		}
		,register: function(username, email, password, confirm) {
			let postParams = new FormData();
			postParams.append("reg_user", true);
			postParams.append("username", username);
			postParams.append("email", email);
			postParams.append("password_1", password);
			postParams.append("password_2", confirm);
			return this.$http.post(this.$store.state.userEndPoint, postParams);
		}
		,fetchLayersFromServer: function() {
			return this.$http.get("https://niva-eu.neuropublic.gr/geoserver2/niva/wfs?service=WFS&version=1.1.0&request=GetCapabilities");
		}
		,fetchLayerByName: function() {
			return this.$http.get("https://niva-eu.neuropublic.gr/geoserver2/niva/wfs?service=WFS&version=1.1.0&request=GetFeature&typenames=niva:Natura2000_Iraklio0&outputFormat=application/json");
		}
	}
}
</script>