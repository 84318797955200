<template>
  <div class="vue-tempalte">
    <!-- Navigation -->
    <!-- Main -->
    <!-- <div id="container">
    <img src="../../public/back.jpg">
    <img src="../../public/imag.png">
    <img src="../../public/back2.jpg">
    <img src="../../public/back3.jpg">
</div> -->
    <div class="App">
       
          <!-- <img style="width: 1200px; display: block; margin: 0px auto;" src="../../public/soalr.png"> -->
        <div class="inner-block-2" >
          <img style="width: 175px; margin-left: 19%;" src="../../public/black.png">
          <h2 style="text-align: left; margin-left:25%; color: #3eb489;">Hello,</h2>
          <h2 v-if="showLogin" style="text-align: left; margin-left:25%; color: white; margin-bottom: 30px;">Welcome</h2>
          <h2 v-if="showRegister" style="text-align: left;margin-left:25%; color: white; margin-bottom: 30px;">Register</h2>
        <form v-if="showLogin" id="login">
            <div class="form-group">
                <input type="text" placeholder="Username"  v-model="username" name="username" class="form-control" :class="{ 'is-invalid': submitted && !username }" />
                <div v-show="submitted && !username" class="invalid-feedback">Username is required</div>
            </div>
            <div class="form-group">
                <input type="password" placeholder="Password"  v-model="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && !password }" />
                <div v-show="submitted && !password" class="invalid-feedback">Password is required</div>
            </div>
            
            
            <div class="form-group" style="display: grid;">
                <button style="width: 50%; margin-left: 25%" @click="onLogin" type="button" id="loginb" class="btn btn-primary" :disabled="loading">
                    <span class="spinner-border spinner-border-sm" v-show="loading"></span>
                    <span class="login">Login</span>
                </button>
                <button type="button" id="registera" @click="showRegister = true; showLogin = false;" style="background-color: transparent !important; border-color: transparent !important;width: 50%; margin-left: 25%" class="btn btn-primary" :disabled="loading">
                    <span class="register" style="color: white !important;">Create Account</span>
                </button>
            </div>    
            
            <div v-if="error" class="alert alert-danger">{{error}}</div>
            </form>
             <form v-if="showRegister" ref="register" id="register">
            <div class="form-group">
                <input type="text" placeholder="Username" v-model="rusername" name="rusername" class="form-control" :class="{ 'is-invalid': registered && !rusername }" />
                <div v-show="registered && !rusername" class="invalid-feedback">Username is required</div>
            </div>
            <div class="form-group">
                <input type="text"  placeholder="Email" v-model="email" name="email" class="form-control" :class="{ 'is-invalid': registered && !email }" />
                <div v-show="registered && !email" class="invalid-feedback">Email is required</div>
            </div> 
            <div class="form-group">
                <input type="password" placeholder="Password" v-model="rpassword" name="rpassword" class="form-control" :class="{ 'is-invalid': registered && !rpassword }" />
                <div v-show="registered && !rpassword" class="invalid-feedback">Password is required</div>
            </div>
            <div class="form-group">
                <input type="password" placeholder="Confirm Password" v-model="confirm" name="confirm" class="form-control" :class="{ 'is-invalid': registered && !confirm }" />
                <div v-show="registered && !confirm" class="invalid-feedback">Confirmation Password is required</div>
            </div>
            
            <div class="form-group" style="display: grid;">
            <button id="registerb" style="width: 50%;margin-left: 25%" v-if="showRegister" @keyup.enter="onRegister" @click="onRegister" type="button" class="btn btn-primary" :disabled="loading">
                    <span class="spinner-border spinner-border-sm" v-show="loading"></span>
                    <span class="login">Register</span>
                </button>
                
                <button type="button" v-if="showRegister" @click="showLogin = true; showRegister = false;" style="background-color: transparent !important; border-color: transparent !important;width: 50%;margin-left: 25%" class="btn btn-primary" :disabled="loading">
                    <span class="register" style="color: white !important;">Login</span>
                </button>
            </div>    
            
              
            
            <div v-if="error2" class="alert alert-danger">{{error2}}</div>
            </form>
              <hr class="dashed">
             <div class="img-login-left">
               
            <a style="cursor:pointer;" @mouseover="hover = true"
      @mouseleave="hover = false" @click="np()"> <img v-if="!hover" style="width: 120px;" src="../../public/n1.png"> <img v-if="hover" style="width: 120px;" src="../../public/n1-color.png"> </a>
            <a style="cursor:pointer;" @mouseover="hover2 = true"
      @mouseleave="hover2 = false" @click="gaia()"> <img v-if="!hover2" style="width: 120px;" src="../../public/g1.png"> <img v-if="hover2" style="width: 120px;" src="../../public/g-color.png"> </a>
             </div>
        
      </div>    
      <!-- <div class="eshapeButton">
	<button v-on:click="showEshape" class="esButton" style="height:50px; border-radius:5px; bottom:5px; right:5px; position: fixed; color: rgb(0, 49, 25) !important; background-color: white !important;border-color: white !important;"><img width="45px" src="../../public/es.png" /></button>
				</div> -->
      <!-- <footer v-if="showEs" style="opacity:0.8 !important" class="foot">
      <div class="container">
          <div class="column-right">
              <img src="../../public/es.png" width="45px" style="margin-right:15px !important">
              <img src="../../public/europe.jpg" width="45px" style="margin-right:5px !important"> The e-shape project has received funding from the European Union's Horizon 2020 research and innovation programme under grant agreement 820852.
          </div>
      </div>
    </footer> -->
    </div> 
    <SuccessModal ref="successmodal" v-bind:value="showRegistrationConfirm"/>
    <Requests ref="requests" />	
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
// import emailjs from 'emailjs-com';
import SuccessModal from "../components/SuccessModal.vue";
import router from '../router/index'
// import { authenticationService } from '../_services/authentication.service';
import Requests from '../components/Requests.vue';


export default {
    components: {
		Requests: Requests,
        SuccessModal: SuccessModal
	},
    data () {
        return {
        interaction: false, 
            username: '',
            password: '',
            rusername: '',
            rpassword: '',
            email: '',
            confirm: '',
            submitted: false,
            registered: false,
            loading: false,
            returnUrl: '',
            error: '',
            error2: '',
            myImage: "../../public/n1.png",
            otherImage: "../../public/g1.png",
            hover: false,
            hover2: false,
            showModal: false,
            showRegister: false,
            showLogin: true,
            user: '',
            showRegistrationConfirm: false,
            showEs: true
        };
    },
    validations: {
      username: { required },
      password: { required }
    },
    created () {
        // redirect to home if already logged in
        if (JSON.parse(localStorage.getItem('currentUser')) !== null) { 
            return router.push('/en');
        }

        // get return url from route parameters or default to '/'
    },
    methods: {
        onLogin () {
            this.submitted = true;

            // stop here if form is invalid
            
            this.$v.$touch();
            if (this.$v.$invalid) {
                return 0;
            }

            this.loading = true;
            this.$refs.requests.login(this.username, this.password).then(response => { this.user = response.body.data["token"]; 
            router.push('/en');
            localStorage.setItem('currentUser', JSON.stringify(this.user));
            },
				error => {
                        this.error = error.body.errors[0];
                        this.loading = false;
            });
        },
        np: function () {   
          window.open("https://www.neuropublic.gr/en/", "_blank");    
        },
        gaia: function () {   
          window.open("http://www.gaiasense.gr/en/gaiasense", "_blank");    
        },
        onRegister () {

            if (this.rusername && this.email && this.rpassword && this.confirm) {
            this.$refs.requests.register(this.rusername, this.email, this.rpassword, this.confirm).then( () => {
				this.$refs.successmodal.setText("Your registration is successful! We will contact you soon via e-mail to activate your account. Thank you.");
                this.rusername = '';
                this.email = '';
                this.rpassword = '';
                this.confirm = '';
                this.registered = false;
                this.error2 = '';
			},  error => {
                        this.error2 = error.body.errors[0];
                        this.loading = false;})
            } else {
                this.registered = true;
            }
        },
        showEshape: function() {
			this.showEs = !this.showEs;
		}
    },
     mounted () {
      this.show = true
      this.interaction = true  
      this.showRegister = false
  } 
};
</script>

<style scoped>

.register:hover {
  text-decoration: underline;
  border-color: transparent !important;
}

.register:focus {
  outline: none !important;
  border: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.button {
 appearance: none;
 outline: none;
 border: none;
 background: none;
 cursor: pointer;
 
 display: inline-block;
 padding: 15px 25px;
 background-image: linear-gradient(to right, #CC2E5D, #FF5858);
 border-radius: 8px;
 
 color: #FFF;
 font-size: 18px;
 font-weight: 700;
 
 box-shadow: 3px 3px rgba(0, 0, 0, 0.4);
 transition: 0.4s ease-out;
 
}

.modal-overlay {
 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 z-index: 98;
 background-color: rgba(0, 0, 0, 0.3);
}

.modal {
 position: fixed;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 z-index: 99;
 
 width: 100%;
 max-width: 400px;
 background-color: #FFF;
 border-radius: 16px;
 
 padding: 25px;
}

.fade-enter-active,
.fade-leave-active {
 transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
 opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
 transition: transform .5s;
}

.slide-enter,
.slide-leave-to {
 transform: translateY(-50%) translateX(100vw);
}

.inner-block-2 {
    background: #343a40;
    box-shadow: 0px 14px 80px rgb(34 35 58 / 20%);
    padding: 25px 45px 30px 45px;
    /* border-radius: 15px; */
    transition: all .3s;
    position: absolute;
    right: 0;
    height: 110%;
    /* justify-content: center; */
    text-align: center;
    display: flex;
    flex-direction: column;
    position: fixed!important;
    top: 0;
    height: 100vh;
    width: 50%;
    max-width: 100%!important;
    margin: 0!important;
    outline: 0;
    transform: translateX(0);
}

.form-control {
    border-radius: 150px;
    margin-left: 25%;
    width: 50%;
    background-color: #3eb489 !important;
    border-color: #3eb489 !important;
}

#loginb {
    background-color: transparent !important;
    border-color: #3eb489 !important;
}

#loginb:hover {
   background-color: #3eb489 !important;
    border-color: #3eb489 !important; 
}

#registerb {
    background-color: transparent !important;
    border-color: #3eb489 !important;
}

#registerb:hover {
   background-color: #3eb489 !important;
    border-color: #3eb489 !important; 
}

hr.dashed {
 border-top: 1px solid #bbb;
    width: 60%;
}

#container {
  position:relative;
  height:100%;
  width:100%;
}
#container img {
  position:absolute;
  left:0;
  height:100%;
  width:100%;
}

@-webkit-keyframes imgFade {
 0% {
   opacity:1;
 }
 17% {
   opacity:1;
 }
 25% {
   opacity:0;
 }
 92% {
   opacity:0;
 }
 100% {
   opacity:1;
 }
}

@-moz-keyframes imgFade {
 0% {
   opacity:1;
 }
 17% {
   opacity:1;
 }
 25% {
   opacity:0;
 }
 92% {
   opacity:0;
 }
 100% {
   opacity:1;
 }
}

@-o-keyframes imgFade {
 0% {
   opacity:1;
 }
 17% {
   opacity:1;
 }
 25% {
   opacity:0;
 }
 92% {
   opacity:0;
 }
 100% {
   opacity:1;
 }
}

@keyframes imgFade {
 0% {
   opacity:1;
 }
 17% {
   opacity:1;
 }
 25% {
   opacity:0;
 }
 92% {
   opacity:0;
 }
 100% {
   opacity:1;
 }
}

#container img {
  -webkit-animation-name: imgFade;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 8s;

  -moz-animation-name: imgFade;
  -moz-animation-timing-function: ease-in-out;
  -moz-animation-iteration-count: infinite;
  -moz-animation-duration: 8s;

  -o-animation-name: imgFade;
  -o-animation-timing-function: ease-in-out;
  -o-animation-iteration-count: infinite;
  -o-animation-duration: 8s;

  animation-name: imgFade;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 8s;
}
#container img:nth-of-type(1) {
  -webkit-animation-delay: 6s;
  -moz-animation-delay: 6s;
  -o-animation-delay: 6s;
  animation-delay: 6s;
}
#container img:nth-of-type(2) {
  -webkit-animation-delay: 4s;
  -moz-animation-delay: 4s;
  -o-animation-delay: 4s;
  animation-delay: 4s;
}
#container img:nth-of-type(3) {
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -o-animation-delay: 2s;
  animation-delay: 2s;
}
#container img:nth-of-type(4) {
  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  -o-animation-delay: 0;
  animation-delay: 0;
}

@media (max-width: 750px) {

.inner-block-2 {
    width: 100% !important;
}

}

@media (max-width: 550px) {
.form-control {
    margin-left: 15% !important;
    width: 70% !important;
}

#registera {
   margin-left: 15% !important;
    width: 70% !important;
}

}

</style>