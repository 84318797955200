<template>
	<div v-bind:id=id class="map">
	</div>
</template>

<script>
import 'ol/ol.css';

import BingMaps from 'ol/source/BingMaps';
import GeoJSON from 'ol/format/GeoJSON';
import {Map, View} from 'ol';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import ImageLayer from 'ol/layer/Image';
import RasterSource from 'ol/source/Raster';
//import WMSCapabilities from 'ol/format/WMSCapabilities';
import TileWMS from 'ol/source/TileWMS';
import {Fill, Stroke, Style, Text} from 'ol/style';
//import Feature from 'ol/Feature';
import {bbox as bboxStrategy} from 'ol/loadingstrategy';

export default {
	name: 'OpenLayersMap'
	,data: function() {
		return {
			layers: null,
			layerVisibility: null,
			map: null,
			selectedFeature: {
				currentStyle: null,
				feature: null
			},
			overlayElement: null,
			defaultLayerStyles: {},
			highlightStyle: new Style({
				stroke: new Stroke({
					color: 'red',
					width: 3,
				}),
				fill: new Fill({
					color: 'rgba(255, 255, 255, 0.1)'
				}),
				text: new Text({
					font: '12px Calibri,sans-serif',
					fill: new Fill({
						color: '#000',
					}),
					stroke: new Stroke({
						color: '#f00',
						width: 3,
					}),
				}),
			})		
		}	
	}
	,props: {
		id: String,
		epsg: String,
		zoom: Number,
		center: Array,
		hover: Boolean
	}
	,methods: {
		init: function() {
			this.map = new Map({
				target: this.id,
				view: new View(
					{
						zoom:this.zoom,
						center: this.center,
						projection: this.epsg
					}
				)
			});
			this.layers = {};
			this.layerVisibility = {};

			
			if (this.hover) {
				this.map.on('pointermove', (e) => {
					if (this.selectedFeature.feature !== null) {
						this.selectedFeature.feature.setStyle(this.selectedFeature.currentStyle);
						this.selectedFeature.feature = null;
						this.selectedFeature.currentStyle = null;
					}
				
					this.map.forEachFeatureAtPixel(e.pixel, (f, layer) => {
						if (!layer.getVisible()) 
							return false;

						this.selectedFeature.feature = f;
						this.selectedFeature.currentStyle = f.getStyle();
						f.setStyle(this.highlightStyle);
						return true;
					});
				});
			}
		}
		,addBingLayerToMap: function (key, style, setMaxZoom=true, zIndex = null) {
			let bingSource = new BingMaps({
				key: key,
				imagerySet: style
			});
			if(setMaxZoom) bingSource.maxZoom=19;
			return this.__addWMSLayerToMap(bingSource, null, zIndex, true);
		}
		,addEventToLayer: function(layerId, eventName, type="click") {
			this.map.on(type, (event) => {
				this.map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {	
					if (layer.ol_uid == layerId) {
						this.$emit(eventName, feature);
					}
				})
			});
		}
		,addOSMLayerToMap: function(extent=null, zIndex, visible=true) {
			let osmSource = new OSM();
			this.__addWMSLayerToMap(osmSource, extent, zIndex, visible);
		}
		,createGEOJSONLayer: function(data, zIndex, zoomToLayer=false, style=null) {
			let geojsonSource = new VectorSource({
					features: new GeoJSON().readFeatures(data)
			});
			
			geojsonSource.forEachFeature((ft) => {
				ft.setId(ft.get("id"));
				if(style != null)
					ft.setStyle(style);
			});		

			let id = this.__addVectorLayerToMap(geojsonSource, zIndex, zoomToLayer);
			this.defaultLayerStyles[this.layers[id]] = style;
			return id;
		}
		,createWMSLayer: function (url, params, zIndex = null, visible=false, extent=null, serverType="mapserver") {
				let tmpTileLayer = new TileWMS({
					url: url,
					params: params,
					serverType: serverType,
					crossOrigin: "anonymous"
				});
			return this.__addWMSLayerToMap(tmpTileLayer, extent, zIndex, visible);
		}
		,createWFSLayer: function(url, layer, zIndex=null, zoomToLayer=false, crs="EPSG:3857",  style=null, version="2.0.0", outputFormat="application/json") {
				let wfsSource = new VectorSource({
					format: new GeoJSON(),
					url: function (extent) {
						return (
							url+'?service=WFS&' +'version=' + version + '&request=GetFeature&typename=' + layer + '&' +
							'outputFormat=' + outputFormat +'&srsname=' + crs +'&' +
							'bbox=' + extent.join(',') + ',EPSG:3857');
					},
					strategy: bboxStrategy
				});
				let id = this.__addVectorLayerToMap(wfsSource, zIndex, zoomToLayer);
				if (style != null)
					this.defaultLayerStyles[this.layers[id]] = style;
				return id;
		}
		,fitToExtent: function(extent){
			this.map.getView().fit(extent);
		}
		,fitToLayerExtent: function(id){
			this.map.getView().fit(this.layers[id].getSource().getExtent());
		}
		,getFeature(layerId, polygonId) {
			return this.layers[layerId].getSource().getFeatureById(polygonId);
		}
		,getMap: function () {
			return this.map;
		}
		,maskWMSByVector: function(wmsId, vectorId) {
			let olSourceRasterOperation = pixels => {
				if (pixels[1][3] === 0) {
					return [0, 0, 0, 0];
				} else {
					return pixels[0];
				}
			};
			let olLayerImage = new ImageLayer({
				source: new RasterSource({
					sources: [
						this.layers[wmsId].getSource(),
						this.layers[vectorId]
					],
				operation: olSourceRasterOperation
				})
			});
			olLayerImage.setExtent(this.layers[wmsId].getExtent());
			this.map.addLayer(olLayerImage);
			let id = olLayerImage.ol_uid;
			this.layers[id] = olLayerImage;
			this.setLayerVisible(id, true);
			return id;
		}
		,removeLayer: function(id) {
			
			if (this.layers[id] == null)
				return 0;
			this.layers[id].getSource().dispose();
			this.layers[id].getRenderer().dispose();
			this.layers[id].setSource(null);
			this.map.removeLayer(this.layers[id]);
			this.layers[id] = null;
			delete this.layers[id];
			delete this.layerVisibility[id];
		}
		,setLayerVisible(id, status) {
			this.layerVisibility[id] = status;
			this.layers[id].setVisible(status);
		}
		,setMaxZoom(zoom) {
			this.map.getView().setMaxZoom(zoom);
		}
		,setMinZoom(zoom) {
			this.map.getView().setMinZoom(zoom);
		},setZoom(zoom) {
			this.map.getView().setZoom(zoom);
		}
		,setLayerStyle(layerId, style) {
			this.layers[layerId].setStyle(style);
			//this.layers[layerId].getSource().getFeatureById(polygonId).setStyle(style);
		}

		,setPolygonStyle(layerId, polygonId, style) {
			this.layers[layerId].getSource().getFeatureById(polygonId).setStyle(style);
		}

		,__addVectorLayerToMap: function(source, zIndex = null, zoomToLayer = false) {

			let tmpLayer = new VectorLayer({
				source:source
			});

			tmpLayer.getSource().getFeatures().forEach( (ft) => {
				ft.on("pointermove", (e) =>{
					console.log(e);
				});
			});

			if (zIndex != null)
				tmpLayer.setZIndex(zIndex);
			
			this.map.addLayer(tmpLayer);
			var id = tmpLayer.ol_uid;
			this.layers[id] = tmpLayer;
			this.layerVisibility[id] = true;
			if (zoomToLayer) {
				let extent = this.layers[id].getSource().getExtent();
				this.map.getView().fit(extent);
			}

			return id;
		},__addWMSLayerToMap: function(source, extent= null, zIndex = null, visible=true) {
			let wmsLayer  = new TileLayer({source: source,
			});
			if (extent != null)
				wmsLayer.setExtent(extent);

			if (zIndex != null)
				wmsLayer.setZIndex(zIndex);
			
			let id = wmsLayer.ol_uid;
			this.layers[id] = wmsLayer;
			this.layers[id].setVisible(visible);
			this.map.addLayer(wmsLayer);
			this.layerVisibility[id] = visible;
			return id;
		}

	}
	,mounted: function() {
		this.init();
	}

}

</script>

<style>
.map {
	width: 100%;
	height: 100%;
	background: #aaaaaa;
}


</style>