import 'bootstrap/dist/css/bootstrap.css';
//import '@fortawesome/fonteawesome-free/css/all.css';
//import '@fortawesome/fonteawesome-free/js/all.js';

import Vue from 'vue';
import Vuex from 'vuex';
import VueResource from 'vue-resource';
import App from './App.vue';
import { BootstrapVue , BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router from './router'
import ElementUI from "element-ui";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/css/main.css'
import Vuelidate from 'vuelidate';
import VueHorizontal from "vue-horizontal";
import CountryFlag from 'vue-country-flag';
import VueSocialSharing from 'vue-social-sharing';

Vue.use(VueSocialSharing);

Vue.component('country-flag', CountryFlag)

Vue.component(VueHorizontal)

Vue.use(Vuelidate);

Vue.use(ElementUI);
Vue.config.productionTip = false;

import { configureFakeBackend } from './_helpers';
configureFakeBackend();

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.use(Vuex);
Vue.use(VueResource);

Vue.config.productionTip = false;

let dateEnd = new Date();


dateEnd.setHours(23);
dateEnd.setMinutes(59);
dateEnd.setSeconds(59);
dateEnd.setMilliseconds(999);

let dateStart  = new Date();
dateStart.setDate(dateStart.getDate()-30);
dateStart.setHours(0);
dateStart.setMinutes(0);
dateStart.setSeconds(0);
dateStart.setMilliseconds(0);

const store = new Vuex.Store({
	state: {
		// serviceEndPoint:"http://tensorflow.neuropublic.gr/zimb/endpoint.php",
		// userEndPoint:"http://tensorflow.neuropublic.gr/eshape/user.php",
		userEndPoint:"https://damage.resagri.eu/user.php",
		serviceEndPoint:"https://damage.resagri.eu/endpoint.php",
		wmsBaseURL: "https://gaia4uws.neuropublic.gr/wms/",
		polygons: null,
		dateStart: dateStart,
		dateEnd: dateEnd,
		tileList: [{"id":"35TLF", "name": "Rhodope, GR"}
		,{"id":"34SFJ", "name": "Thessaly, GR"},
		{"id":"34SEJ", "name": "Thessaly (2/2), GR"},
		{"id":"35SLU", "name": "Crete, GR"},
		{"id":"34SFH", "name": "Orchomenos, GR"}
	]
	},
	mutations: {
		setPolygons(state, polygons) {
			Vue.set(state, "polygons", polygons);
		}
		,setDates(state, dates){
			Vue.set(state, "dateStart", dates.start);
			Vue.set(state, "dateEnd", dates.end);
		}
		,setDateStart(state, date){
			Vue.set(state, "dateStart", date);
		}
		,setDateEnd(state, date){
			Vue.set(state, "dateEnd", date);
		}			
	}
	
});

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
});

  