<template>
	<div>
		<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.2.0/css/all.css" integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ" crossorigin="anonymous" />
		
		<div>
			<OpenLayersMap class="map" id="olmap" epsg="EPSG:3857" v-bind:zoom=12 v-bind:center=[2499584.3854,4787403.6981] v-bind:hover=true ref="olmap" v-on:polygonclick="showParcelInfo($event)"></OpenLayersMap>
			<div>
			<div id="configuration" class="config-lg">
				<div class="configbutton">
					<i class="btn btn-default fas fa-sliders-h fa-2x" style="color: #444444; opacity:0.8;" v-b-toggle.sidebar-left></i>
				</div>
				
			</div>
			<div class="fmenu"><ul style="position: absolute; right: 79px; top:8px;">         <li @click="cropmonitoring"><a href="#">Crop Monitoring</a></li>         <li style="background-color:#3EB489"><a href="#">Damage Assessment</a></li>         <li @click="highlevel"><a href="#">High Level Monitoring</a></li>         </ul></div>
					<ul style="position: absolute; right: 79px; top:-45px !important;" class="menu cf">
  <li class="menuF" style="height:40px;">
    <a class="menuFirst" style="margin-top: -4px;cursor: pointer;color: rgb(0, 124, 186);">Menu</a>
    <ul class="submenu">
      <li><a style="cursor: pointer;color: rgb(0, 124, 186);" @click="highlevel">High Level Monitoring</a></li>
      <li><a style="cursor: pointer;color: rgb(0, 124, 186);" @click="cropmonitoring">Crop Monitoring</a></li>
    </ul>			
  </li>
</ul>
						<div class="map-tools">
			
			<div class="navigation" style="position: absolute; top: 8px; left: 570px;">
							<b-button style="" size="sm" :disabled="disablePrev" @click="previous()" v-b-tooltip.hover title="Previous Parcel" pill ><i class="fas fa-arrow-left"></i></b-button>
							<b-button style="margin-left: 10px;" :disabled="disableNext" size="sm" @click="next()" v-b-tooltip.hover title="Next Parcel" pill ><i class="fas fa-arrow-right"></i></b-button>
							</div>
			<b-card class="searchDam" style="   top: 5px; 
    width: 170px;
    height: 36px;
    right: -385px;
    opacity: 0.9;
    cursor: pointer;
    display: block;
    margin: 0;
    text-align: left;
    padding-left: 20px;
	border-radius: 0px 5px 5px 0px !important;">
                                <div class="card-body row no-gutters align-items-center" style="margin-top: 4px;width: 220px;margin-left: -40px;height: 0;width: 200px;">
                                    <!--end of col-->
                                    <div class="col" style="top: -32px;">
                                        <input v-model="searchInput" @keyup.enter="search" name="searchInput" class="form-control form-control-sm form-control-borderless" type="search" placeholder="Search field id">
                                    </div>
                                    <!--end of col-->
                                    <div class="col-auto" style="top: -30px;margin-right:5px;">
                                        <button style="outline: none;box-shadow: none;" class="btn btn-md" @click="search"><i class="fas fa-search h6 text-body"></i></button>
                                    </div>
                                    <!--end of col-->
                                </div>
					</b-card>
						</div>
			<div class= "rightPanel" style="z-index:1000000">
    <b-sidebar v-model="isMainSidebarOpen" no-close-on-backdrop id="sidebar-left" title="AgSa" shadow>
		<template #title>
        <img src="../../public/resa.png" width="170px">
      </template>	<template #footer>	
       <div class="d-flex bg-dark text-light align-items-center px-3 py-2">	<b-button style="outline: none;box-shadow: none; background-color: transparent !important;
    border-color: transparent !important; color: white;" variant="link" v-b-toggle.sidebar-description>How it works</b-button>
        <strong class="mr-auto"></strong>
        <b-button size="sm" @click="logout">Log Out</b-button>
       </div>
      </template>
		<b-card style="border-color: white !important;">
			<b-card-body style="text-align: center; display: flex !important;">
				<h4 style="margin-left: 73px;
    font-size: 19px;
    font-weight: 600;
    color: black;" class="card-title">Damage Assessment</h4>
			</b-card-body>
		</b-card>
				
	<b-card> <b-card-body>	<div class = "leftMenuLabels">
							<select style="    border: 0px;
    outline: 0px;
    font-size: 23px;
    font-weight: bold;
    line-height: 29px;
    scroll-behavior: smooth;
    color: #1a90ff;
	border-bottom: 1px solid #222;"  class="form-control form-control-sm" name="tiles" id="tiles2" v-on:change="setActiveTile($event)" v-bind:value=activeTileID>

								<option v-for="(val, key, index) in tileList"  v-bind:key="index" v-bind:value=val.id>{{val.name}} </option>
							</select>	<select style="    border: 0px;
    outline: 0px;
    font-size: 13px;
    font-weight: bold;
    line-height: 29px;
    scroll-behavior: smooth;
    color: #1a90ff;
	width: 80px;
	border-bottom: 1px solid #222;"  class="form-control form-control-sm" name="tiles" id="tiles2">

								<option v-for="(val, key, index) in years"  v-bind:key="index" v-bind:value=val.id>{{val.name}} </option>
							</select>
                          </div> <div style="padding-top:30px;" class="place-analytics__title"><h2>Events</h2> </div>	<b-table v-if="!showVineyard" striped hover  id="eventTable" ref="eventsTable" selectable responsive flex :select-mode="rankSelectMode" @row-selected="eventRowSelected" :items="damageList" :fields="events">	 <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">	
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>	</b-table>	<b-table v-if="showVineyard" striped hover  id="eventTable" ref="eventsTable" selectable responsive flex :select-mode="rankSelectMode" @row-selected="eventRowSelected" :items="vineList" :fields="events">	 <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">	
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>	</b-table> </b-card-body>	</b-card>	<b-card v-if="!showVineyard">	<b-card-body>	<div class="place-analytics__title"><h2>Crop Types</h2> </div>		<b-table ref="selectableTable" striped hover responsive selectable flex :select-mode="selectMode" @row-selected="onRowSelected" :items="cropList" :fields="fieldsCrops">	<template #cell(crop)="data">
        <img :src="data.item.image" width="30px;" v-b-tooltip.hover :title="data.item.name"/> 
      </template>	<template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template></b-table>	</b-card-body>		
	</b-card>	<b-card v-if="showVineyard">	<b-card-body>	<div class="place-analytics__title"><h2>Crop Types</h2> </div>		<b-table ref="selectableTable" striped hover responsive selectable flex :select-mode="selectMode" @row-selected="onRowSelected" :items="vineyardList" :fields="fieldsCrops">	<template #cell(crop)="data">
        <img :src="data.item.image" width="30px;" v-b-tooltip.hover :title="data.item.name"/> 
      </template>	<template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template></b-table>	</b-card-body>		
	</b-card>	<b-card>	<b-card-body>	<div class="place-analytics__title"><h2>Parcels Ranking</h2> </div>	<div id="rankTable">	<b-table :style="{'max-height':this.max_height}" selectable :select-mode="rankSelectMode" @row-selected="rankRowSelected" striped hover responsive flex :items="mergeList" :fields="ranking"></b-table>	</div>	<b-button style="outline: none;box-shadow: none; background-color: transparent !important;
    border-color: transparent !important; color: white;margin-left: 110px;margin-top: -20px;" id="showButton" variant="link" v-if="showAllbutton" @click="showAll">Show All</b-button>	<b-button style="outline: none;box-shadow: none; background-color: transparent !important;
    border-color: transparent !important; color: white;margin-left: 110px;margin-top: -20px;" id="showButton" variant="link" v-if="!showAllbutton" @click="showLess">Show Less</b-button>	</b-card-body>		
	</b-card>
	<div id="share" style="margin-left:5px;cursor:pointer; position:relative; bottom:-40px; left:0;"><div class="btn_wrap">
        <span style="margin-top: 10px; color: white !important;">Share <i style="font-size:14px; color: white !important;" class="fa fa-share-alt"></i></span>
        <div class="container">
            <ShareNetwork style="margin-top:15px;"
    network="facebook"
    url="https://damage.resagri.eu"
    title=""
    description=""
    quote=""
    hashtags="resagri , neuropublic"
  >
    <i style="font-size: 1.8rem;" class="fab fa-facebook"></i>
</ShareNetwork>
            <ShareNetwork	style="margin-left:10px; margin-top:15px;"
    network="linkedin"
    url="https://damage.resagri.eu"
    title=""
    description=""
    quote=""
    hashtags="resagri , neuropublic"
  >
    <i style="font-size: 1.8rem;" class="fab fa-linkedin"></i>
</ShareNetwork>
           <ShareNetwork	style="margin-left:10px; margin-top:15px;"
    network="twitter"
    url="https://damage.resagri.eu"
    title=""
    description=""
    quote=""
    hashtags="resagri , neuropublic"
  >
    <i style="font-size: 1.8rem;" class="fab fa-twitter"></i>
</ShareNetwork>
        </div>
    </div>


<a class="dr-url" target="_blank"></a> </div>
	<img @click="np()" id="np_logo" style="cursor:pointer; margin-left:190px; filter: brightness(0) invert(1);" src="../../public/np_logo.png" width="170px">
	</b-sidebar>
    <b-sidebar v-model="isParcelSidebarOpen" no-close-on-backdrop id="damage-sidebar-right" title="Field Data" lazy shadow>
		<b-card style="border-bottom: 1px solid;color: black;" title="Parcel Info">
        <b-card-body>
          <div>
					<div>
						<div class="row1" style="display:flex;">
							<h6 style="margin-left:30px;"><span class="desc">Parcel Id</span> <div style="" v-if = "ndviPolygonData !== null"><span style="font-size: 20px;font-weight:700;color: #476757;">{{ ndviPolygonData.parcel_id }}</span></div></h6>
							<h6 style="margin-left:90px;"><span class="desc">Crop</span> <div style="" v-if = "ndviPolygonData !== null"><span style="font-size: 20px;font-weight:700; text-transform:capitalize;color: #476757;">{{ndviPolygonData.crop}}</span></div></h6>
						</div>	
						<div class="row2" style="display:flex; margin-top:30px;">
							<h6 style="margin-left:30px;"><span class="desc">Area</span> <div style="" v-if = "ndviPolygonData !== null"><span style="font-size: 20px;font-weight:700;color: #476757;">{{((ndviPolygonData.area) / 10000).toFixed(2)}}</span> <b style="color: #003119;" v-if = "ndviPolygonData !== null">ha</b></div> </h6>
							<h6 style="margin-left:90px;"><span class="desc">Growing Season</span> <div style="" v-if = "ndviPolygonData !== null"><span style="font-size: 20px;font-weight:700;color: #476757;">{{ndviPolygonData.season}}</span></div></h6>
						</div>
						<div class="row3" style="display:flex; margin-top:30px;">
							<h6 style="margin-left:30px;"><span class="desc">Damaged Area</span> <div style="" v-if = "ndviPolygonData !== null"><span style="font-size: 20px;font-weight:700;color: #476757;">{{((ndviPolygonData.damage_metric)).toFixed(2)}}</span> <b style="color: #003119;" v-if = "ndviPolygonData !== null">%</b></div> </h6>
							
						</div>
					</div>
					</div>
					
        </b-card-body>
      </b-card>
      <b-card style="border-bottom: 1px solid;color: black;" title="Event Info">
        <b-card-body>
			<div>
					<div>
						<div class="row1" style="display:flex;">
							<h6 style="margin-left:30px;"><span class="desc">Date of Event</span> <div style="" v-if = "ndviPolygonData !== null"><span style="font-size: 20px;font-weight:700;color: #244c34;">{{date_start}}</span></div></h6>
							
						</div>	
						<div class="row2" style="display:flex; margin-top:30px;">
							<h6 style="margin-left:30px;"><span class="desc">Date Assessed</span> <div style="" v-if = "ndviPolygonData !== null"><span style="font-size: 20px;font-weight:700;color: #244c34;">{{date_assessed}}</span></div> </h6>
							
						</div>
					</div>
					</div>
        </b-card-body>
		<div>
</div>
      </b-card>	
    </b-sidebar>
	<b-sidebar id="sidebar-description" title="Description" right shadow>
<b-card>
        <b-card-body>
          <div style="white-space: pre-wrap;">
        <span class="description" style="line-height:30px" v-html="longText"></span>
					<div id="info" class=" inforight">
					</div>
				</div>
        </b-card-body>
      </b-card>
		</b-sidebar>
    </div>
		</div>
		</div>	<div class="eshapeButton">
	<button v-on:click="showEshape" class="esButton" style="height:50px; border-radius:5px; bottom:5px; right:5px; position: fixed; color: rgb(0, 49, 25) !important;"><img width="45px" src="../../public/es.png" /></button>
				</div>
		<footer v-if="showEs" style="opacity:0.9 !important" class="foot">
      <div class="container">
          <div class="column-right">
              <img src="../../public/es.png" width="45px" style="margin-right:15px !important">
              <img src="../../public/europe.jpg" width="45px" style="margin-right:5px !important"> The e-shape project has received funding from the European Union's Horizon 2020 research and innovation programme under grant agreement 820852.
          </div>
      </div>
    </footer>
	<footer class="damage-foot-left">
      <div class="container">
          <div class="column-right">	<div style="text-align: left;">	<h5 style="font-size: 18px;">Damage Grade</h5>	</div>
             <div id="maplegend" >	
				<img style="margin-top:0px; width: 280px;" src="../../public/legend2.png">
							</div>	<div> <p id="mild" style="position: absolute; left:15px;">Mild</p><p id="moderate" style="position: absolute; left:125px;">Moderate</p><p id="severe" style="position: absolute; left:255px;">Severe</p></div>
          </div>
      </div>
    </footer>
		<Modal ref="wmsmodal" v-bind:value="showWMSWarningModal"/>
		<Requests ref="requests" />	
	</div>
</template>


<script>
import OpenLayersMap from './OpenLayersMap.vue';
import Requests from './Requests.vue';
import Modal from "./Modal.vue";
import {router} from '../router/index'
import {Fill, Stroke, Style, Text} from 'ol/style';


export default {
	name: "ESHAPEMapView"
	,components: {
		OpenLayersMap: OpenLayersMap,
		Requests: Requests,
		Modal: Modal
	}
	,computed: {
		dateRange: {
			get() {
				return{
					start: this.$store.state.dateStart,
					end: this.$store.state.dateEnd				
				}
			}
			,set(value) {
				this.$store.commit("setDates", value);
			}
		}
		,tileList: {
			get() {return [
			{id: "0", name: "Thessaly"},
			{id: "1", name: "Crete"}
			]
				// return this.$store.state.tileList;
			}
		}
		,cropList: {
			get() {return [
			{id: "0", image: "co.png", name: "Cotton"},
			{id: "1", image: "toma.png", name: "Tomato"}
			]}
		},
		vineyardList: {
			get() {return [
			{id: "2", image: "gra.png", name: "Vineyard"}
			]}
		}
		,damageList: {
			get() {return [
			{id: "21", type: "Hail / Rainfall", region: "N_LARISAS", city: 'Larisa', date: "18/09/2020"},
			{id: "18", type: "Hail / Rainfall", region: "N_LARISAS", city: 'Larisa', date: "03/07/2020"},
			{id: "59", type: "Hail / Rainfall", region: "N_LARISAS", city: 'Larisa', date: "03/07/2021"}
			]}
		}
		,vineList: {
			get() {return [
			{id: "67", type: "Heat", region: "N_HRAKLEIOU", city: 'Iraklio', date: "15/05/2020"}
			]}
		}
		,years: {  
			get() {return [
			{id: "0", name: "2020"}
			// {id: "1", name: "2020"},
            // {id: "2", name: "2021"}
			]}
		}
		,activeGroup:{
			get() {
				return this.activeTileInfo[this.activeTileID]["layer_group"];
			},set(value) {
				if(event.type === "change") {
				this.activeTileInfo[this.activeTileID]["layer_group"] = value;
				}
			}
		}
	}
	,data: function(){
		return {	fields: [
          {
            key: 'name',
            sortable: true
          },
        ],	fieldsCrops: [
          {
            key: 'crop'
          },	'selected'
        ],
		events: [
          {
            key: 'type'
          },{
            key: 'date'
          }, {key: 'city'},	'selected'
        ],
		ranking: [
          {
            key: '#'
          },{
            key: 'parcel id',
			sortable: true
          },{
            key: 'damaged area',
			sortable: true
          },
        ],
		max_height: '296px',
		showVineyard: false,
		rankingList: [],
		sortList: [],
		mergeList: [],
		selectMode: 'multi',
		rankSelectMode: 'single',
        selected: [],
		showAllbutton: true,
		region: '',
		showEs: true,
		searchInput: '',
		isMainSidebarOpen: true,
		disablePrev: true,
		disableNext: false,
		polygon: -1,
		polygonId: 0,
		firstTime: true,
		date_assessed: '',
		date_start: '',
		eventId: null,
		isParcelSidebarOpen: false,
		crops: [],
		cropsIds: [],
		allCrops: null,
			longText: `<p><b>Crop monitoring & damage assessment combing satellite imagery and in situ measurements</b>

<b>How to get started?</b>
Choose a date range on the bottom-left panel of the map image. Also, select your AOI on the field below the date picker panel. On the image layer field you can choose from a drop-down list with all the available satellite imagery for your selection. Switch among the various satellite image composites on the web buttons at the bottom of the panel.

<b>What you see in the image layers?</b>
<b>RGB</b> This colour composite is as close to true colour (what you see with naked eye).

<b>CIR</b> The colour infrared band or near-infrared (NIR) composite is meant to emphasize healthy and unhealthy vegetation.As chlorophyll reflects near-infrared light, this band composition is useful for analysing vegetation.More specifically, areas in red have better vegetation health. Dark areas are water and urban areas are white.

<b>NDVI</b> The Normalised Difference Vegetation Index is a straightforward numerical indicator that can be used to identify vegetation and provide a measure of its health and vitality.NDVI results are presented as a  “red-green” color map, where each color corresponds to a certain range of values.The red-orange-yellow tints indicate bare soil or dead/sparse vegetation, and all shades of green are a sign of normal to dense vegetation cover.

<b>Data products</b>
<b>NDVI graph</b>
The NDVI values, for each crop parcel, measured from Sentinel2 satellite are plotted in a temporal graph for each parcel. The shaded region is obtained from historical NDVI time-series.

<b>Field measurements</b>
Field measurements from Gaiatron stations are recorded capturing the temperature, precipitation, wind speed and relative humidity on a daily basis for all parcels within the AOI.`,
			
			ndviPolygonData: null,
			fieldColors: null,
			defaultPolygonStyle: new Style({
				stroke: new Stroke({
					color: 'yellow',
					width: 3,
				}),
				fill: new Fill({
					color: 'rgba(255, 255, 255, 0.1)'
				}),
				text: new Text({
					font: '12px Calibri,sans-serif',
					fill: new Fill({
						color: '#000',
					}),
					stroke: new Stroke({
						color: '#f00',
						width: 3,
					})
				})
			}),
			polygonStyles: {
				Polygon: new Style({
					stroke: new Stroke({
						color: 'yellow',
						width: 3,
					}),
					fill: new Fill({
						color: 'rgba(255, 255, 255, 0.1)'
					}),
					text: new Text({
						font: '12px Calibri,sans-serif',
						fill: new Fill({
							color: '#000',
						}),
						stroke: new Stroke({
							color: '#f00',
							width: 3,
						})
					})
				})
                
			},
			olMap: null
			,bingLayer: null
			,polygonLayer: null
			,currentWMSLayerGroup: null
			,currentWMSLayerIds: null
			,showWMSWarningModal: false
			,showConfigPanel: true
			,activeTileInfo: null
			,activeTileID: null
		}
	}
	,methods:{	onRowSelected(items) {
        this.selected = items;
		if (this.selected.length == 0) {
			this.$refs.olmap.removeLayer(this.polygonLayer);
			this.$refs.olmap.removeLayer(this.maskId);
			this.mergeList = [];
		}
		if (this.selected.length == 1) {
			this.fetchPolygons(this.selected[0].name.toLowerCase(), false);
		} else if ( this.selected.length == 2  && !this.firstTime ) {
			this.fetchPolygons("all", false);
		}
		this.firstTime = false;
      },	rankRowSelected(item) {if (item[0]) {let extent = this.$refs.olmap.getFeature(this.polygonLayer, item[0].id).getGeometry().getExtent();	this.$refs.olmap.fitToExtent(extent);	this.showParcelInfo(item[0].id.toString());}},
		fetchPolygons: function(type, firstTime) {	this.$refs.olmap.removeLayer(this.polygonLayer);
			this.$refs.olmap.removeLayer(this.maskId);
			console.log(type);
			console.log(this.eventId);
			this.$refs.requests.fetchDamagePolygons(type, this.eventId).then((response) => {
				if (response.body.data.polygon_data == null)
					return 0;
					console.log(response.body.data.polygon_data)
				this.date_assessed = response.body.data.date_assessed;
				this.date_start = response.body.data.date_start;
                this.allCrops = response.body.data.polygon_data;
				this.crops = [];
				this.cropsIds = [];
				this.rankingList = [];
				Object.keys(this.allCrops.features).forEach(key => {
						this.crops.push(this.allCrops.features[key]);
						this.cropsIds.push(this.allCrops.features[key].properties.id);
				});

				Object.keys(this.crops).forEach(key => {
					this.rankingList.push({id: this.crops[key].properties.id, parcel: this.crops[key].properties.id, percent: this.crops[key].properties.damage_metric})
				});

				this.sortList = this.rankingList.sort((a, b) => b.percent - a.percent );
				this.mergeList = [];
				Object.keys(this.sortList).forEach(key => {
					this.mergeList.push({id: this.sortList[key].id,'#': Number(key)+1, 'parcel id': this.sortList[key].id, 'damaged area': this.sortList[key].percent.toFixed(2) + '%'})
				});

				
				this.polygonLayer = this.$refs.olmap.createGEOJSONLayer(response.body.data.polygon_data, 2, false);
				
				this.$refs.olmap.addEventToLayer(this.polygonLayer, "polygonclick");	
				let params = {
					LAYERS: this.eventId+"_damage_map",
					TILED: true
				}

				this.damageMapId = this.$refs.olmap.createWMSLayer('https://gaia4uws.neuropublic.gr/wms/ESHAPE/2020/'+this.region, params,1, false);
				this.maskId = this.$refs.olmap.maskWMSByVector(this.damageMapId, this.polygonLayer);
				this.createPolygonsVisibility();
				this.$refs.olmap.fitToLayerExtent(this.polygonLayer);
				if (firstTime) {
				this.$refs.selectableTable.selectAllRows();}
			});	},
			eventRowSelected(item) {if (item[0]) {this.eventId = item[0].id; 
			if (item[0].id == '67') {this.showVineyard=true;} else {this.showVineyard=false;}	this.region=item[0].region;	this.fetchPolygons("all", true); }},
		init: function() {
			
			//setting NDVI as active background layer type
			if (JSON.parse(localStorage.getItem('currentUser')) == null) {
				router.push("/login");
			} else {

			this.$refs.eventsTable.selectRow(0);
			this.$refs.selectableTable.selectAllRows();

			//setting the id for the current active tile
			this.activeTileID = this.tileList[0].id;
			this.activeTileInfo = new Array();

			this.currentWMSLayerIds = new Array();
			//setting background
			this.bingLayer = this.$refs.olmap.addBingLayerToMap("ArYycvKoklvKbZOTjPv-9Ckak7Nb0hmARptRDBrRUbrPVC7QN6maRg1fZur0X7UC", "aerial");
			this.$refs.olmap.setMaxZoom(18);
			this.$refs.olmap.setMinZoom(7);
			//bring in the field polygons
			this.olMap = this.$refs.olmap.getMap();		}			
		}
		,setActiveTile: function (event) {
			this.activeTileID = this.tileList[event.target.value].id;
			
			if (event.target.value == 1) {
				this.showVineyard=true;
			} else {
				this.showVineyard=false;
			}
			console.log(this.$refs.eventsTable.selectRow(0));
		}
		,createPolygonsVisibility: function(){
				Object.keys(this.crops).forEach((key)=>{
				this.$refs.olmap.setPolygonStyle(this.polygonLayer,this.crops[key].properties.id,this.polygonStyles.Polygon);
			});
		},
		logout: function () {
			localStorage.removeItem('currentUser');
			router.push("/login");
		},
		search: function() {
			const { searchInput } = this;
			if (searchInput) {
				let extent;
				if (this.$refs.olmap.getFeature(this.polygonLayer, searchInput)) {
				extent = this.$refs.olmap.getFeature(this.polygonLayer, searchInput).getGeometry().getExtent();}
				else {
					this.$refs.wmsmodal.setText("No data available. Please specify another field id.");
					this.showWMSWarningModal = true;
				}
				if (extent) {
				this.$refs.olmap.fitToExtent(extent);
				this.showParcelInfo(searchInput);
				//this.polygon = Number(searchInput);
				}
			}
		},
		showParcelInfo: function(event , str) {
			let polygonId;
			
			if (str !== 'nav') {
			if (typeof(event) == 'string') {
                polygonId = event;
				this.polygonId = Number(polygonId);
			} else {
				polygonId = event.get("id");
				this.polygonId = polygonId;
			}
				Object.keys(this.crops).forEach(key => {
				if (this.crops[key].properties.id === this.polygonId) {
						this.polygon = Number(key);
					}
				});

				if (this.cropsIds[this.polygon + 1]) { 
				this.disablePrev = false;
				this.disableNext = false;
			} else {
				this.disableNext = true;
			}

			if (!this.cropsIds[this.polygon - 1]) {
				this.disablePrev = true;
			} else {
				this.disablePrev = false;
			}

			} else {
				polygonId = event;
				this.polygonId = Number(polygonId);
			}

			
                this.isParcelSidebarOpen = true
				this.ndviPolygonData = [];
				let ft = this.$refs.olmap.getFeature(this.polygonLayer, polygonId);
				this.ndviPolygonData["season"] = ft.getProperties().year;
				this.ndviPolygonData["parcel_id"] = ft.getProperties().id;
				this.ndviPolygonData["crop"] = ft.getProperties().crop;
				this.ndviPolygonData["area"] = ft.getProperties().affected_area;
				this.ndviPolygonData["damage_metric"] = ft.getProperties().damage_metric;
				
		},
		next: function() {
			let extent;
			
			if (this.cropsIds[this.polygon + 1]) {
				
				if (this.$refs.olmap.getFeature(this.polygonLayer, this.cropsIds[this.polygon + 1])) {
				extent = this.$refs.olmap.getFeature(this.polygonLayer, this.cropsIds[this.polygon + 1]).getGeometry().getExtent();
				}
				else {
					this.disableNext = true;
					this.$refs.wmsmodal.setText("No data available. Please specify another field id.");
					this.showWMSWarningModal = true;
				}
				if (extent) {
				this.$refs.olmap.fitToExtent(extent);
				this.showParcelInfo(this.cropsIds[this.polygon + 1] , "nav");
				this.polygon = this.polygon + 1;
				
				}}

				if (this.cropsIds[this.polygon + 1] == undefined) {
					this.disableNext = true;
				}
				
				if (this.cropsIds[this.polygon - 1] == undefined) {
				this.disablePrev = true;
			} else {
				this.disablePrev = false;
			}
		},
		previous: function() {

				this.disableNext = false;
			let extent;
			if (this.cropsIds[this.polygon - 1]) {
				if (this.$refs.olmap.getFeature(this.polygonLayer, this.cropsIds[this.polygon - 1])) {
					
				extent = this.$refs.olmap.getFeature(this.polygonLayer, this.cropsIds[this.polygon - 1]).getGeometry().getExtent();
				
				}
				else {
					this.$refs.wmsmodal.setText("No data available. Please specify another field id.");
					this.showWMSWarningModal = true;
				}
				if (extent) {
				this.$refs.olmap.fitToExtent(extent);
				this.showParcelInfo(this.cropsIds[this.polygon - 1], "nav");}
				this.polygon = this.polygon - 1;
				
			} 
			if (this.cropsIds[this.polygon - 1] == undefined) {
				this.disablePrev = true;
			}
				
				
				
			},
		np: function () {   
          window.open("https://www.neuropublic.gr/en/", "_blank");    
        },
		highlevel: function () {
			//this.$refs.olmap.removeLayer(this.activeTileInfo[this.activeTileID]["wms_layer_id"]);
			router.push("/highlevel");
		},
		cropmonitoring: function () {
			//this.$refs.olmap.removeLayer(this.activeTileInfo[this.activeTileID]["wms_layer_id"]);
			router.push("/");
		},
		showEshape: function() {
			this.showEs = !this.showEs;
		},
		showAll: function() {
			this.max_height = 'unset';
			this.showAllbutton = false;
		},
		showLess: function() {
			this.max_height = '296px';
			this.showAllbutton = true;
		}
	},mounted:function() {
		this.init();
	},filters: {
        Round(value) {
              return Number((value).toFixed(0));
        },	
}
}
</script>


<style>

h6 {
	font:13px/1.1 'Graphik', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

.cf:before,
.cf:after {
  content: " ";
  display: table;
}

.cf:after {
  clear: both;
}

.cf {
  *zoom: 1;
}

/* Mini reset, no margins, paddings or bullets */
.menu,
.submenu {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* Main level */
.menu {			
  margin: 50px auto;
  width: 800px;
  /* http://www.red-team-design.com/horizontal-centering-using-css-fit-content-value */
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;	
}

.menu > li {
  background: white;
  float: left;
  position: relative;
  border-radius: 5px 5px 5px 5px;
  opacity: 0.9;
}

.menu a {
  display: block;
  color: rgb(0, 124, 186);
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}			

.menu > li > a {
  padding: 1em 2em;
}

/* Dropdown */
.submenu {
  position: absolute;
  width: 180px;
  left: 50%; margin-left: -100px;
  transform-origin: left top;
}

.submenu li {
  background-color: white;
  position: relative;
  overflow: hidden;	
  border-radius: 5px;	
}						

.submenu > li > a {
  padding: 12px;
  font-size: 12px;			
}

.submenu > li::after {
  content: '';
  position: absolute;
  top: -125%;
  height: 100%;
  width: 100%;			
  box-shadow: 0 0 50px rgba(0, 0, 0, .9);			
}		

/* Odd stuff */
.submenu > li:nth-child(odd){
  transform: skewX(-25deg) translateX(0);
}

.submenu > li:nth-child(odd) > a {
  transform: skewX(25deg);
}

.submenu > li:nth-child(odd)::after {
  right: -50%;
  transform: skewX(-25deg) rotate(3deg);
}				

/* Even stuff */
.submenu > li:nth-child(even){
  transform: skewX(25deg) translateX(0);
}

.submenu > li:nth-child(even) > a {
  transform: skewX(-25deg);
}

.submenu > li:nth-child(even)::after {
  left: -50%;
  transform: skewX(25deg) rotate(3deg);
}

/* Show dropdown */
.submenu,
.submenu li {
  opacity: 0;
  visibility: hidden;			
}

.submenu li {
  transition: .2s ease transform;
}

.menu > li:hover .submenu,
.menu > li:hover .submenu li {
  opacity: 1;
  visibility: visible;
}		

.menu > li:hover .submenu li:nth-child(even){
  transform: skewX(25deg) translateX(15px);			
}

.menu > li:hover .submenu li:nth-child(odd){
  transform: skewX(-25deg) translateX(-15px);			
}

.table-active td{
	background-color: white !important;
}

#rankTable .table-responsive {
    transition: height 0.15s, max-height 0.15s;
    position: unset;
    overflow: hidden;
	}

#rankTable tbody tr [aria-colindex="1"] {
	color: #3EB489 !important;
}	

#damage-sidebar-right .b-sidebar-header {
	border-bottom: 1px solid black;
}

#damage-sidebar-right {
border: 1px solid lightgray;
}

#eventTable td {
	font-size: 12px;
}

</style>