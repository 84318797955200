import { BehaviorSubject } from 'rxjs';

import { handleResponse } from '@/_helpers';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

function login(username, password) {
    return this.$refs.requests.login(username, password)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);

            return user;
        });


        // this.$refs.requests.login(this.username, this.password).then(response => { this.user = response.body.data["token"]; router.push(this.returnUrl);
        //     localStorage.setItem('currentUser', JSON.stringify(this.user));
        //     currentUserSubject.next(this.user);},
		// 		error => {
        //                 this.error = error.body.errors[0];
        //                 this.loading = false;
        //     });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}
