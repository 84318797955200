<template>
	<div>
		<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.2.0/css/all.css" integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ" crossorigin="anonymous" />
	<div>
		<OpenLayersMap class="map" id="olmap" epsg="EPSG:3857" v-bind:zoom=7 v-bind:center=[2631351.83,4615959.26] v-bind:hover=true ref="olmap" v-on:polygonclick="showPolygonTimeline($event)"></OpenLayersMap>
	<div>
			<div id="configuration" class="config-lg">
				<div class="configbutton">
					<i class="btn btn-default fas fa-sliders-h fa-2x" style="margin-top:5px;" v-b-toggle.sidebar-left></i>
				</div>
				
			</div>
			
			<div class="fmenu"><ul style="position: absolute; right: 79px; top:8px;">         <li @click="cropmonitoring"><a href="#">Crop Monitoring</a></li>         <li @click="damage"><a href="#">Damage Assessment</a></li>         <li style="background-color:#3EB489"><a href="#">High Level Monitoring</a></li>         </ul></div>
			<ul style="position: absolute; right: 79px; top:-41px;" class="menu cf">
   <li class="menuF" style="height:40px;">
    <a class="menuFirst" style="margin-top: -4px;cursor: pointer;color: rgb(0, 124, 186);">Menu</a>
    <ul class="submenu">
      <li><a style="cursor: pointer;color: rgb(0, 124, 186);" @click="damage">Damage Assessment</a></li>
      <li><a style="cursor: pointer;color: rgb(0, 124, 186);" @click="cropmonitoring">Crop Monitoring</a></li>
    </ul>			
  </li>
</ul>
						
			<div class= "rightPanel" style="z-index:1000000">
    <b-sidebar no-close-on-backdrop v-model="isMainSidebarOpen" id="sidebar-left" title="AgSa" shadow>
		<template #title>
        <a href="https://resagri.eu/">
        <img src="../../public/resa.png" width="170px">
			</a>
      </template>	<template #footer>
       <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
        <strong class="mr-auto"></strong>
        <b-button size="sm" @click="logout">Log Out</b-button>
       </div>
      </template>
		<b-card style="border-color: white;">
			<b-card-body style="text-align: center; display: flex !important;">
				<h4 style="margin-left: 63px;
    font-size: 19px;
    font-weight: 600;
    color: black;" class="card-title">High Level Monitoring</h4>
			</b-card-body>
		</b-card>
		
		<!-- <b-button v-if="user.role==='Admin'" size="lg" @click="admin" style="margin-left: 15px !important;" v-b-tooltip.hover title="Admin" pill variant="outline"><i class="fas fa-user-astronaut"></i></b-button> -->
		
	
	<b-card title="">
        <b-card-body>
          <div class = "">	<div>
						<div class="row1" style="display:flex;">
							<h6 style="margin-left:30px;"><span class="desc">Prefecture</span><div style="" v-if = "info !== null"><span style="font-size: 20px;font-weight:700;color: #007cba;">{{ info.perfecture }}</span></div> </h6>
							<h6 style="margin-left:90px;"><span class="desc">Date</span><div style="" v-if = "info !== null"><span style="font-size: 20px;font-weight:700;color: #007cba;">{{ info.date }}</span></div> </h6>
						</div>
						<div class="row2" style="display:flex; margin-top:30px;">
							<h6 style="margin-left:30px;"><span class="desc">Awareness Type</span><div style="" v-if = "info !== null"><span style="font-size: 20px;font-weight:700;color: #007cba;">{{ info.type }}</span></div> </h6>
							<h6 style="margin-left:90px;"><span class="desc">Total Crop Area</span><div style="" v-if = "info !== null"><span style="font-size: 20px;font-weight:700;color: #007cba;">{{ info.area }}</span></div> </h6>
						</div>	
						<div class="row2" style="display:flex; margin-top:30px;">
							<h6 style="margin-left:30px;"><span class="desc">Estimated Claims</span><div style="" v-if = "info !== null"><span style="font-size: 20px;font-weight:700;color: #007cba;">{{ info.claims }}</span></div> </h6>
							<h6 style="margin-left:90px;"><span class="desc">Affected Area</span><div style="" v-if = "info !== null"><span style="font-size: 20px;font-weight:700;color: #007cba;">{{ info.loss }}</span></div> </h6>
						</div>
					</div>	</div>
        </b-card-body>
      </b-card>	<div id="share" style="margin-left:5px;cursor:pointer; position:absolute; bottom:60px; left:0;"><div class="btn_wrap">
        <span style="margin-top: 10px; color: white !important;">Share <i style="font-size:14px; color: white !important;" class="fa fa-share-alt"></i></span>
        <div class="container">
            <ShareNetwork style="margin-top:15px;"
    network="facebook"
    url="https://damage.resagri.eu"
    title=""
    description=""
    quote=""
    hashtags="resagri , neuropublic"
  >
    <i style="font-size: 1.8rem;" class="fab fa-facebook"></i>
</ShareNetwork>
            <ShareNetwork	style="margin-left:10px; margin-top:15px;"
    network="linkedin"
    url="https://damage.resagri.eu"
    title=""
    description=""
    quote=""
    hashtags="resagri , neuropublic"
  >
    <i style="font-size: 1.8rem;" class="fab fa-linkedin"></i>
</ShareNetwork>
           <ShareNetwork	style="margin-left:10px; margin-top:15px;"
    network="twitter"
    url="https://damage.resagri.eu"
    title=""
    description=""
    quote=""
    hashtags="resagri , neuropublic"
  >
    <i style="font-size: 1.8rem;" class="fab fa-twitter"></i>
</ShareNetwork>
        </div>
    </div>


<a class="dr-url" target="_blank"></a> </div>		<img @click="np()" id="np_logo" style="cursor:pointer; position:absolute; bottom:55px; right:0;filter: brightness(0) invert(1);" src="../../public/np_logo.png" width="170px">
	</b-sidebar>	</div>
		</div>
		</div>	<div class="eshapeButton">
	<button v-on:click="showEshape" class="esButton" style="height:50px; border-radius:5px; bottom:5px; right:5px; position: fixed; color: rgb(0, 49, 25) !important;"><img width="45px" src="../../public/es.png" /></button>
				</div>
		<footer v-if="showEs" style="opacity:0.8 !important" class="foot">
      <div class="container">
          <div class="column-right">
              <img src="../../public/es.png" width="45px" style="margin-right:15px !important">
              <img src="../../public/europe.jpg" width="45px" style="margin-right:5px !important"> The e-shape project has received funding from the European Union's Horizon 2020 research and innovation programme under grant agreement 820852.
          </div>
      </div>
    </footer>
	<Requests ref="requests" />	
	</div>
</template>


<script>
import OpenLayersMap from './OpenLayersMap.vue';
import {router} from '../router/index';
import { authenticationService } from '../_services/authentication.service';
import Requests from './Requests.vue';
import {Fill, Stroke, Style, Text} from 'ol/style';

export default {
	name: "HighLevelMonitoring"
	,components: {
		OpenLayersMap: OpenLayersMap,
		Requests: Requests
	}
	,data: function(){
		return {
			user: authenticationService.currentUserValue,
			isAccordion1Visible: false,
			isMainSidebarOpen: true,
			polygonLayer: null,
			showEs: true,
			info: null,
			polygonStyles: {
				PolygonOrange: new Style({
					stroke: new Stroke({
						color: 'transparent',
						width: 3,
					}),
					fill: new Fill({
						color: '#e98434'
					}),
					text: new Text({
						font: '12px Calibri,sans-serif',
						fill: new Fill({
							color: '#000',
						}),
						stroke: new Stroke({
							color: '#f00',
							width: 3,
						})
					})
				}),
				PolygonGreen: new Style({
					stroke: new Stroke({
						color: 'transparent',
						width: 3,
					}),
					fill: new Fill({
						color: '#699b33'
					}),
					text: new Text({
						font: '12px Calibri,sans-serif',
						fill: new Fill({
							color: '#000',
						}),
						stroke: new Stroke({
							color: '#f00',
							width: 3,
						})
					})
				}),
				PolygonYellow: new Style({
					stroke: new Stroke({
						color: 'transparent',
						width: 3,
					}),
					fill: new Fill({
						color: '#fad200'
					}),
					text: new Text({
						font: '12px Calibri,sans-serif',
						fill: new Fill({
							color: '#000',
						}),
						stroke: new Stroke({
							color: '#f00',
							width: 3,
						})
					})
				}),
				PolygonRed: new Style({
					stroke: new Stroke({
						color: 'transparent',
						width: 3,
					}),
					fill: new Fill({
						color: '#d94335'
					}),
					text: new Text({
						font: '12px Calibri,sans-serif',
						fill: new Fill({
							color: '#000',
						}),
						stroke: new Stroke({
							color: '#f00',
							width: 3,
						})
					})
				}),
                
			}
		}
	}
	,methods:{
		init: function() {
			
			if (JSON.parse(localStorage.getItem('currentUser')) == null) {
				router.push("/login");
			} else {
			let params = {
								LAYERS: "NOMOI OKXE",
								TILED: true
							}

						
			this.$refs.olmap.createWMSLayer(this.$store.state.wmsBaseURL+"okxe", params,1, true);
			this.osmLayer = this.$refs.olmap.addOSMLayerToMap();}
			this.$refs.olmap.setMaxZoom(10);
			this.$refs.olmap.setMinZoom(6);
			this.$refs.requests.fetchPolygonsHighLevel().then((response) => {
				if (response.body.data == null)
					return 0;
                
				this.polygonLayer = this.$refs.olmap.createGEOJSONLayer(response.body.data, 2, false);
				this.$refs.olmap.addEventToLayer(this.polygonLayer, "polygonclick");	
				this.$refs.olmap.setPolygonStyle(this.polygonLayer, 8, this.polygonStyles.PolygonOrange);
				this.$refs.olmap.setPolygonStyle(this.polygonLayer, 28, this.polygonStyles.PolygonGreen);
				this.$refs.olmap.setPolygonStyle(this.polygonLayer, 41, this.polygonStyles.PolygonGreen);
				this.$refs.olmap.setPolygonStyle(this.polygonLayer, 45, this.polygonStyles.PolygonRed);
			});
		}, 
		logout: function () {
			localStorage.removeItem('currentUser');
			router.push("/login");
		},
		admin: function () {
            router.push('/admin');
		},
		cropmonitoring: function () {
			router.push("/");
		},
		damage: function () {
			router.push("damage");
		},
		showEshape: function() {
			this.showEs = !this.showEs;
		},
		showPolygonTimeline: function(event) {
			this.info = {};
			if (event.id_ === 8) {
				this.info = {perfecture: 'Larissa', date: '18/09/2020', type: 'Flood', area: '2396.53 ha', claims: '14', loss: '184.26 ha'};
			}
			if (event.id_ === 45) {
				this.info = {perfecture: 'Karditsa', date: '18/09/2020', type: 'Flood', area: '1129.18 ha', claims: '29', loss: '500.35 ha'};
			}
			if (event.id_ === 41) {
				this.info = {perfecture: 'Magnesia', date: '18/09/2020', type: 'Flood', area: '51.86 ha', claims: '3', loss: '15.97 ha'};
			}
			if (event.id_ === 28) {
				this.info = {perfecture: 'Rodopi', date: '18/09/2020', type: 'Flood', area: '2000 ha', claims: '0', loss: '0'};
			}
		},
		np: function () {   
          window.open("https://www.neuropublic.gr/en/", "_blank");    
        }
	},mounted:function() {
		this.init();	
	}	
}
</script>

