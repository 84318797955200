<template>
<div id="successModal" class="emodal" v-show=toggle>
  <!-- Modal content -->
  
  <div style="background-color: #3eb489 !important;" class="modal-content">
    <div  style="text-align:center; color: white;" id="mymodaltext"><p>init</p></div>
    <div  style="text-align:center;"><button v-on:keyup.enter="toggle=false" class="btn btn-sm btn-info"  v-on:click="toggle=false">Close</button></div>
  </div>
  
</div>
</template>
    
<script>
  export default {
    name: 'SuccessModal'
    ,data:function() {
      return {
        toggle: null,
        showRegisterModal: false
      }
    },props:{
      value: {
        required: true
      }
    }
    ,methods:{
      init: function() {
        this.toggle = this.value;
      }
      ,toogleVisibility: function(){
        this.toggle = !this.toggle;
      }
      ,setText:function(text) {
        document.getElementById("mymodaltext").innerHTML="<p>"+text+"</p>";
        this.toggle = true;
      }
    },mounted: function() {
      this.init();
    }
  };
    </script>
    
    
<style lang="css" scoped>
 /* The Modal (background) */
.emodal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 400px; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 20px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
} 
</style>