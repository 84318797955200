<template>
	<div>
		<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.2.0/css/all.css" integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ" crossorigin="anonymous" />
		<meta name='og:image' content='../../public/resa.png'>
		<div>
			<OpenLayersMap class="map" id="olmap" epsg="EPSG:3857" v-bind:zoom=13 v-bind:center=[2813350.19856,5010629.67127] v-bind:hover=true ref="olmap" v-on:polygonclick="showNDVITimeline($event)"></OpenLayersMap>
			
			<div>

				<div style="position:absolute; top:0; width: 100%; background: white !important;" id="header">
					
				</div>

			<div id="configuration" class="config-lg">
				<div class="configbutton">
					<i class="btn btn-default fas fa-sliders-h fa-2x" style="opacity:0.8;margin-top:5px;" v-b-toggle.sidebar-left></i>
				</div>
				
			</div>
			<div class="lang" style="outline: none;box-shadow: none;position: absolute; top:10px;">
			<SwitchLang @changed="onChange" :initLang="initLang" :options="options2" />
			</div>	
			<div class="fmenu"><ul style="position: absolute; right: 79px; top:8px;">         <li style="background-color:#3EB489"><a href="#" data-text="Home">Crop Monitoring</a></li>         <li @click="damage"><a href="#" data-text="About">Damage Assessment</a></li>         <li @click="highlevel"><a href="#" data-text="Services">High Level Monitoring</a></li>         </ul></div>
			<ul style="position: absolute; right: 79px; top:-41px;" class="menu cf">
   <li class="menuF" style="height:40px;">
    <a class="menuFirst" style="margin-top: -4px;cursor: pointer;color: rgb(0, 124, 186);">Menu</a>
    <ul class="submenu">
      <li><a style="cursor: pointer;color: rgb(0, 124, 186);" @click="damage">Damage Assessment</a></li>
      <li><a style="cursor: pointer;color: rgb(0, 124, 186);" @click="highlevel">High Level Monitoring</a></li>
    </ul>			
  </li>
</ul>
						<div style="position: absolute; right: 5540px; top:50px;">
					<b-dropdown id="dropdown-1" text="Select Language" class="m-md-2">
    <b-dropdown-item @click="selectLanguage('gr')" ><country-flag country='gr' size='normal'/></b-dropdown-item>
    <b-dropdown-item @click="selectLanguage('gb')" ><country-flag country='gb' size='normal'/></b-dropdown-item>
  </b-dropdown>	
  
                        </div>
						<div class="map-tools">
							<div class="navigation" style="position: absolute; top: 10px; left: 720px;">
							<b-button style="" size="sm" :disabled="disablePrev" @click="previous()" v-b-tooltip.hover title="Previous Parcel" pill ><i class="fas fa-arrow-left"></i></b-button>
							<b-button style="margin-left: 10px;" :disabled="disableNext" size="sm" @click="next()" v-b-tooltip.hover title="Next Parcel" pill ><i class="fas fa-arrow-right"></i></b-button>
							</div>
			<b-dropdown style="right: -400px; " size="md" class="m-2" id="mapLayer">
				<template slot="button-content">
					<div style="display: flex;">
					<span style="">Map Layer</span>
					<i style="margin-top:8px;margin-left:10px;font-size:12px;" class="fa fa-chevron-down"></i>
				
					</div>
        
    </template>
      <div id="col-2" style="margin-legt: 15px !important; margin-right: 15px;">	 <div id="col-1" style="margin-bottom: 20px; ">
								<div v-for="(val,key) in layerTypes" v-bind:key="key" class="btn-group btn-group-toggle " data-toggle="buttons">
									<label v-bind:id="key+'_label'" class="btn btn-outline-primary btn-sm mr-1" v-bind:class="{active: val.active}">
										<input type="radio" name="options" autocomplete="off"  v-on:click="changeLayerType(key)" style="vertical-align:middle text-align:center">{{val.name}}
									</label>
								</div>	</div>	</div>
    </b-dropdown>
			<b-card style="   top: -43.7px; 
    width: 170px;
    height: 36px;
    right: -535px;
    opacity: 0.9;
    cursor: pointer;
    display: block;
    margin: 0;
    text-align: left;
    padding-left: 20px;
	border-radius: 0px 5px 5px 0px !important;"  id="search">
                                <div class="card-body row no-gutters align-items-center searchField" style="margin-top: 4px;width: 220px;margin-left: -40px;height: 0;width: 200px;">
                                    <!--end of col-->
                                    <div class="col" style="top: -32px;">
                                        <input v-model="searchInput" @keyup.enter="search" name="searchInput" class="form-control form-control-sm form-control-borderless" type="search" id="fieldSearch" placeholder="Search field id">
                                    </div>
                                    <!--end of col-->
                                    <div class="col-auto" style="top: -30px;margin-right:5px;">
                                        <button style="outline: none;box-shadow: none;" class="btn btn-md" @click="search"><i class="fas fa-search h6 text-body"></i></button>
                                    </div>
                                    <!--end of col-->
                                </div>
					</b-card>
						</div>
			<div class= "rightPanel" style="z-index:1000000">
    <b-sidebar no-close-on-backdrop v-model="isMainSidebarOpen" id="sidebar-left" title="AgSa" shadow>
		<template #title>
			<a href="https://resagri.eu/">
        <img src="../../public/resa.png" width="170px">
			</a>
      </template>	<template #footer>
       <div class="d-flex bg-dark text-light align-items-center px-3 py-2">	<b-button style="outline: none;box-shadow: none; background-color: transparent !important;
    border-color: transparent !important; color: white;" variant="link" v-b-toggle.sidebar-description>How it works</b-button>
        <strong class="mr-auto"></strong>
        <b-button size="sm" @click="logout">Log Out</b-button>
       </div>
      </template>
		<b-card style="border-color: white !important;">
			<b-card-body style="text-align: center; display: flex !important;">
				<h4 style="margin-left: 96px;
    font-size: 19px;
    font-weight: 600;
    color: black;" class="card-title">Crop Monitoring</h4>
			</b-card-body>
		</b-card>	<b-card style="margin-top:20px;" title="Growing Season"> <b-card-body>	<div class = "leftMenuLabels">
							<select v-on:change="setGrowingSeason($event)" style="    border: 0px;
    outline: 0px;
    font-size: 18px;
    font-weight: bold;
    line-height: 29px;
    scroll-behavior: smooth;
    color: #1a90ff;
	text-align:center;
	border-bottom: 1px solid #222;"  class="form-control form-control-sm" name="tiles" id="tiles2">

								<option v-for="(val, key, index) in years"  v-bind:key="index" v-bind:value=val.id>{{val.name}} </option>
							</select>
                          </div> </b-card-body>	</b-card>	
	<b-card title="">
        <b-card-body>
          <div class = "leftMenuLabels" style="border: 2px solid #3EB489 !important;">	<b-col md="" class="">
      <!-- Our triggering (target) element -->
      <!-- <b-button id="popover-reactive-1" ref="button">
        Choose Date Range
      </b-button> --><div style="display: flex;">	<p style="color: white !important;
    font-family: 'Ubuntu';
    font-size: 16px; margin-bottom:0px; padding-top: 5px; width:80px;">Date from</p>
 <b-form-datepicker :min="min" :max="max" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" id="from-datepicker" v-bind:value=dateRange.start v-on:input="setDataFrom($event)" class="mb-2"></b-form-datepicker>	</div>
 <div style="display: flex;">	<p style="color: white !important;
    font-family: 'Ubuntu';
    font-size: 16px; margin-bottom:0px; padding-top: 5px; width:80px;">Date to</p>	<b-form-datepicker :min="min" :max="max" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" id="to-datepicker" v-bind:value=dateRange.end v-on:input="setDataTo($event)" class="mb-2"></b-form-datepicker>	</div>
	<!-- <b-popover
      target="popover-reactive-1"
      triggers="click"
      :show.sync="popoverShow"
      placement="top"
      ref="popover"
    >
      <template #title>
        <b-button style="background-color: white !important;margin-top: -3px;" @click="onClose" class="close" aria-label="Close">
          <span class="d-inline-block" aria-hidden="true">&times;</span>
        </b-button>
        Date Range
      </template>	<div>	 <Datepicker style="right: 5px;" ref="datepicker" title-position="left" trim-weeks :columns="1" is-range v-bind:value=dateRange v-on:input="getData($event)"></Datepicker>						</div>	</b-popover> -->
      </b-col>
						
			</div>
        </b-card-body>
      </b-card>	<b-card title="Area of interest"> <b-card-body>	<div class = "leftMenuLabels">
							<select  class="form-control form-control-sm" name="tiles" id="tiles" v-on:change="setActiveTile($event)" v-bind:value=activeTileID>

								<option v-for="(val, key, index) in tileList"  v-bind:key="index" v-bind:value=val.id>{{val.name}} </option>
							</select>
                          </div> </b-card-body>	</b-card>	<b-card title="Image Layer"> <b-card-body>	<div class = "leftMenuLabels">
							<select class="form-control form-control-sm" v-if="currentWMSLayerGroup != null" name="dates" id="dates" v-on:change="updateMapView($event.target.value)" v-model="activeGroup">
								<option v-for="(val, key, index) in currentWMSLayerGroup[activeTileID]" v-bind:key="index" v-bind:value=key>{{val.acquisition_ts}}</option>
							</select></div>	</b-card-body>	</b-card>
							<div id="share" style="margin-left:5px;cursor:pointer; position:absolute; bottom:60px; left:0;"><div class="btn_wrap">
        <span style="margin-top: 10px; color: white !important;">Share <i style="font-size:14px; color: white !important;" class="fa fa-share-alt"></i></span>
        <div class="container">
            <ShareNetwork style="margin-top:15px;"
    network="facebook"
    url="https://damage.resagri.eu"
    title=""
    description=""
    quote=""
    hashtags="resagri , neuropublic"
  >
    <i style="font-size: 1.8rem;" class="fab fa-facebook"></i>
</ShareNetwork>
            <ShareNetwork	style="margin-left:10px; margin-top:15px;"
    network="linkedin"
    url="https://damage.resagri.eu"
    title=""
    description=""
    quote=""
    hashtags="resagri , neuropublic"
  >
    <i style="font-size: 1.8rem;" class="fab fa-linkedin"></i>
</ShareNetwork>
           <ShareNetwork	style="margin-left:10px; margin-top:15px;"
    network="twitter"
    url="https://damage.resagri.eu"
    title=""
    description=""
    quote=""
    hashtags="resagri , neuropublic"
  >
    <i style="font-size: 1.8rem;" class="fab fa-twitter"></i>
</ShareNetwork>
        </div>
    </div>


<a class="dr-url" target="_blank"></a> </div>
							<img @click="np()" id="np_logo" style="cursor:pointer; position:absolute; bottom:55px; right:0;filter: brightness(0) invert(1);" src="../../public/np_logo.png" width="170px">
	</b-sidebar>
    <b-sidebar v-model="isParcelSidebarOpen" no-close-on-backdrop id="sidebar-right" title="Field Data" lazy shadow>
		<b-card title="Parcel Info">
        <b-card-body>
          <div>
					<div>
						<div class="row1" style="display:flex;">
							<h6 style="margin-left:30px;"><span class="desc">Parcel Id</span> <div style="" v-if = "ndviPolygonData !== null"><span style="font-size: 20px;font-weight:700;color: #007cba;">{{ ndviPolygonData.parcel_id }}</span></div></h6>
							<h6 style="margin-left:90px;"><span class="desc">Crop</span> <div style="" v-if = "ndviPolygonData !== null"><span style="font-size: 20px;font-weight:700; text-transform:capitalize;color: #007cba;">{{ndviPolygonData.crop}}</span></div></h6>
						</div>	
						<div class="row2" style="display:flex; margin-top:30px;">
							<h6 style="margin-left:30px;"><span class="desc">Area</span> <div style="" v-if = "ndviPolygonData !== null"><span style="font-size: 20px;font-weight:700;color: rgb(0, 124, 186);">{{((ndviPolygonData.area) / 10000).toFixed(2)}}</span> <b style="color: rgb(0, 124, 186);" v-if = "ndviPolygonData !== null">ha</b></div> </h6>
							<h6 style="margin-left:90px;"><span class="desc">Growing Season</span> <div style="" v-if = "ndviPolygonData !== null"><span style="font-size: 20px;font-weight:700;color: #007cba;">{{ndviPolygonData.season}}</span></div></h6>
						</div>
					</div>
					</div>
					
        </b-card-body>
      </b-card>
      <b-card title="Data Products">
        <b-card-body>
			<b-button style="float: right;" v-b-modal.modal-center v-b-tooltip.hover title="Large Screen" pill variant="outline"><i class="fas fa-search-plus"></i></b-button>
          <b-tabs content-class="mt-3" lazy>
              <b-tab title="NDVI"><HighCharts class="chartPanel panelElement" :options="ndviChartOptions"></HighCharts></b-tab>
				<b-tab title="Field Measurements">	<b-tabs pills class="nav-pills-fields" lazy>	<b-tab><template slot='title'>
            <i v-b-tooltip.hover title="Temperature" style="color:RGB(0,50,96)" class="fas fa-thermometer-half"></i>
        </template><HighCharts class="chartPanel panelElement" :options="temperatureChartOptions"></HighCharts></b-tab>	<b-tab><template slot='title'>
            <i v-b-tooltip.hover title="Precipitation" style="color:RGB(0,50,96)" class="fas fa-cloud-rain"></i>
        </template><HighCharts class="chartPanel panelElement" :options="precipitationChartOptions"></HighCharts></b-tab>	<b-tab><template slot='title'>
            <i v-b-tooltip.hover title="Wind" style="color:RGB(0,50,96)" class="fas fa-wind"></i>
        </template><HighCharts class="chartPanel panelElement" :options="windChartOptions"></HighCharts></b-tab>	<b-tab><template slot='title'>
            <i v-b-tooltip.hover title="Humidity" style="color:RGB(0,50,96)" class="fas fa-tint"></i>
        </template><HighCharts class="chartPanel panelElement" :options="humidityChartOptions"></HighCharts></b-tab> 	</b-tabs>	</b-tab>
            </b-tabs>
        </b-card-body>
		<div>
</div>
      </b-card>	
    </b-sidebar>
	<b-sidebar id="sidebar-description" title="Description" right shadow>
<b-card>
        <b-card-body>
          <div style="white-space: pre-wrap;">
        <span class="description" style="line-height:30px" v-html="longText"></span>
					<div id="info" class=" inforight">
					</div>
				</div>
        </b-card-body>
      </b-card>
		</b-sidebar>

  <b-modal size="xl" id="modal-center" centered title="Data Products">
    <b-card>
        <b-card-body>
          <b-tabs content-class="mt-3" lazy>
              <b-tab style="background-color: black;" title="NDVI"><HighCharts class="chartPanel panelElement" :options="ndviChartOptions"></HighCharts></b-tab>
				<b-tab title="Field Measurements">	<b-tabs pills class="nav-pills-fields" lazy>	<b-tab><template slot='title'>
            <i v-b-tooltip.hover title="Temperature" style="color:RGB(0,50,96)" class="fas fa-thermometer-half"></i>
        </template><HighCharts class="chartPanel panelElement" :options="temperatureChartOptions"></HighCharts></b-tab>	<b-tab><template slot='title'>
            <i v-b-tooltip.hover title="Precipitation" style="color:RGB(0,50,96)" class="fas fa-cloud-rain"></i>
        </template><HighCharts class="chartPanel panelElement" id="rain" :options="precipitationChartOptions"></HighCharts></b-tab>	<b-tab><template slot='title'>
            <i v-b-tooltip.hover title="Wind" style="color:RGB(0,50,96)" class="fas fa-wind"></i>
        </template><HighCharts class="chartPanel panelElement" :options="windChartOptions"></HighCharts></b-tab>	<b-tab><template slot='title'>
            <i v-b-tooltip.hover title="Humidity" style="color:RGB(0,50,96)" class="fas fa-tint"></i>
        </template><HighCharts class="chartPanel panelElement" :options="humidityChartOptions"></HighCharts></b-tab> 	</b-tabs>	</b-tab>
            </b-tabs>
        </b-card-body>
      </b-card>
  </b-modal>
    </div>
		</div>
		</div>	
		<!-- <hooper v-if=currentWMSLayerGroup :itemsToShow="4">
  <slide v-for="(val, key, index) in currentWMSLayerGroup[activeTileID]" v-bind:key="index" v-bind:value=key :class="{ 'active': date === key }">
    <div  @click="updateMapView(val.acquisition_ts)" style="width: 100%; height: 100%;"><span style="color: white !important;"> {{val.acquisition_ts}} </span></div>
  </slide>	<hooper-navigation slot="hooper-addons"></hooper-navigation>	</hooper>	 -->
  <div class="eshapeButton">
	<button v-on:click="showEshape" class="esButton" style="height:50px; border-radius:5px; bottom:5px; right:5px; position: fixed; color: rgb(0, 49, 25) !important;"><img width="45px" src="../../public/es.png" /></button>
				</div>
		<footer v-if="showEs" style="opacity:0.9 !important; bottom: 5px !important;" class="foot">
      <div class="container">	
          <div class="column-right">	
              <img src="../../public/es.png" width="45px" style="margin-right:15px !important">
              <img src="../../public/europe.jpg" width="45px" style="margin-right:5px !important"> The e-shape project has received funding from the European Union's Horizon 2020 research and innovation programme under grant agreement 820852.
          </div>
      </div>
    </footer>
	<footer v-if="ndviShow" class="foot-left">
      <div class="container">
          <div class="column-right">	<div style="text-align: left;">	<h5 style="font-size: 18px;">NDVI scale</h5>	</div>
             <div id="maplegend" >	
				<img style="margin-top:0px; width: 280px;" src="../../public/ndvi.png">
							</div>	<div id="numbers"> <p style="position: absolute; left:20px;">0</p><p style="position: absolute; left:65px;">0.2</p><p style="position: absolute; left:125px;">0.4</p><p style="position: absolute; left:185px;">0.6</p><p style="position: absolute; left:240px;">0.8</p><p style="position: absolute; left:290px;">1</p></div>
          </div>
      </div>
    </footer>
		<Modal ref="wmsmodal" v-bind:value="showWMSWarningModal"/>
		<Requests ref="requests" />	
	</div>
</template>


<script>
import OpenLayersMap from './OpenLayersMap.vue';
import Requests from './Requests.vue';
import {Chart} from 'highcharts-vue';
import Highcharts from "highcharts";
import HighChartsMore from "highcharts/highcharts-more";
import Modal from "./Modal.vue";
// import Datepicker from 'v-calendar/lib/components/date-picker.umd'
import {router} from '../router/index'
import {Fill, Stroke, Style, Text} from 'ol/style';
import CountryFlag from 'vue-country-flag'
import SwitchLang from "vue-switch-lang";
// import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper';
// import 'hooper/dist/hooper.css';

HighChartsMore(Highcharts);

export default {
	name: "ESHAPEMapView"
	,components: {
		OpenLayersMap: OpenLayersMap,
		Requests: Requests,
		HighCharts: Chart,
		Modal: Modal,
		// Datepicker: Datepicker,
		CountryFlag,
		SwitchLang,
		// Hooper,Slide, HooperNavigation
	}
	,computed: {
		dateRange: {
			get() {
				return{
					start: this.$store.state.dateStart,
					end: this.$store.state.dateEnd				
				}
			}
			,set(value) {
				this.$store.commit("setDates", value);
			}
		}
		,tileList: {
			get() {
				return this.$store.state.tileList;
			}
		}
		,activeGroup:{
			get() {
				if (this.currentDate == null) {
					return 0; }
				else {
					return this.currentDate;
				}
			},set(value) {
				if(event.type === "change") {
				this.activeTileInfo[this.activeTileID]["layer_group"] = value;
				}
			}
		}
		,years: {  
			get() {return [
			{id: "0", name: "2022"},
			{id: "1", name: "2021"},
			{id: "2", name: "2020"}
			]}
		}
	}
	,data: function(){
		return {	options: {responsive: [
          { end: 576, size: 1 },
          { start: 576, end: 768, size: 2 },
          { start: 768, end: 992, size: 3 },
          { size: 4 },
        ],},
		slides: [
			{num: 1},{num: 1},{num: 1},{num: 1},{num: 1},{num: 1},{num: 1},{num: 1},{num: 1}
		],
		initLang: {
        title: "en",
        flag: "gb"
      },	popoverShow: false,
      options2: [
        {
          title: "el",
          flag: "gr"
        }
      ],	type: '',	previousType: '',
		searchInput: '',
		date: 0,
		showEs: true,
		isMainSidebarOpen: true,
		isParcelSidebarOpen: false,
			longText: `<p><b>Crop monitoring & damage assessment combing satellite imagery and in situ measurements</b>

<b>How to get started?</b>
Choose a date range on the bottom-left panel of the map image. Also, select your AOI on the field below the date picker panel. On the image layer field you can choose from a drop-down list with all the available satellite imagery for your selection. Switch among the various satellite image composites on the web buttons at the bottom of the panel.

<b>What you see in the image layers?</b>
<b>RGB</b> This colour composite is as close to true colour (what you see with naked eye).

<b>TCI</b> The colour infrared band or near-infrared (NIR) composite is meant to emphasize healthy and unhealthy vegetation.As chlorophyll reflects near-infrared light, this band composition is useful for analysing vegetation.More specifically, areas in red have better vegetation health. Dark areas are water and urban areas are white.

<b>NDVI</b> The Normalised Difference Vegetation Index is a straightforward numerical indicator that can be used to identify vegetation and provide a measure of its health and vitality.NDVI results are presented as a  “red-green” color map, where each color corresponds to a certain range of values.The red-orange-yellow tints indicate bare soil or dead/sparse vegetation, and all shades of green are a sign of normal to dense vegetation cover.

<b>Data products</b>
<b>NDVI graph</b>
The NDVI values, for each crop parcel, measured from Sentinel2 satellite are plotted in a temporal graph for each parcel. The shaded region is obtained from historical NDVI time-series.

<b>Field measurements</b>
Field measurements from Gaiatron stations are recorded capturing the temperature, precipitation, wind speed and relative humidity on a daily basis for all parcels within the AOI.`,

			ndviPolygonData: null,
			from: '',
			to: '',
			fieldColors: null,
			defaultPolygonStyle: new Style({
				stroke: new Stroke({
					color: 'yellow',
					width: 3,
				}),
				fill: new Fill({
					color: 'rgba(0,0,0,0.1)'
				}),
				text: new Text({
					font: '12px Calibri,sans-serif',
					fill: new Fill({
						color: '#000',
					}),
					stroke: new Stroke({
						color: '#f00',
						width: 3,
					})
				})
			}),
			polygonStyles: {
				Polygon: new Style({
					stroke: new Stroke({
						color: 'yellow',
						width: 3,
					}),
					fill: new Fill({
						color: 'rgba(255, 255, 255, 0.1)'
					}),
					text: new Text({
						font: '12px Calibri,sans-serif',
						fill: new Fill({
							color: '#000',
						}),
						stroke: new Stroke({
							color: '#f00',
							width: 3,
						})
					})
				})
                
			}


			,olMap: null
			,year: null
			,bingLayer: null
			,polygonLayer: null
			,currentWMSLayerGroup: null
			,currentWMSLayerIds: null
			,showWMSWarningModal: false
			,showConfigPanel: true
			,disablePrev: true
			,disableNext: false
			,currentDate: null
			,min: ''
			,max: ''
			,polygon: -1
			,polygons: []
			,layerTypes: {
				"tci": {name: "RGB",  active: true},
				"cir": {name: "CIR",  active: false},
				"ndvi":{name: "NDVI", active: false},
				"bing":{name: "Base Bing Aerial", active: false}
			}
			,activeTileInfo: null
			,activeTileID: null
            ,ndviShow: false
			,genPolygons: null
			,ndviChartOptions: {
				title: {
					text: "NDVI Diagram"
				}
				,chart: {
        backgroundColor: '#282c34',
        type: 'area'
    }
				,xAxis: {	title: {
						enabled: true
						,text: 'Date'
					},type: 'datetime',
            dateTimeLabelFormats: {
                millisecond: '%e/%m/%y',
                second: '%e/%m/%y',
                minute: '%e/%m/%y',
                hour: '%e/%m/%y',
                day: '%e/%m/%y',
                week: '%e/%m/%y',
                month: '%e/%m/%y',
                year: '%e/%m/%y'
            }	},
				yAxis: {
					title: {
						enabled: true
						,text: 'NDVI Values'
					},
					min: -1.0,
					max: 1.0,
					startOnTick: true,
					endOnTick: true,
					showLastLabel: true,
					tickPositions: [0, 0.2, 0.4, 0.6, 0.8, 1],

				}
				,tooltip: {
					split: false,
					shared:true,
					backgroundColor: 'black'
				}
				,series: []
				,legend: {
					enabled: true,
					align: 'right',
					layout: 'vertical',
					verticalAlign: 'middle',
					//itemMarginTop: 0,
					//itemMarginBottom: 0,
					y: 50,
					x: 5,
					style: {
						fontSize: '10px',
					}
				},
				credits: {
					enabled: false
				}
			}
			,temperatureChartOptions: {
				title: {
					text: "Temp Diagram"
				}
				,chart: {
        backgroundColor: '#282c34',
        type: 'area'
    }
				,xAxis: {
					title: {
						enabled: true,
						text: 'Date'
						,categories:[]
			}
			,startOnTick: true
					,endOnTick: true
					,showLastLabel: true
					,type: "category"
					,labels: {
						enabled: false
						,rotation:-45
					}
				}
				,yAxis: {
					title: {
						enabled: true
						,text: 'Temperature (°C)'
					},
					min: -1.0,
					max: 1.0,
					startOnTick: true,
					endOnTick: true,
					showLastLabel: true

				}
				,tooltip: {
					split: false,
					shared:true,
					backgroundColor: 'black'
				}
				,series: []
				,legend: {
					enabled: true,
					align: 'right',
					layout: 'vertical',
					verticalAlign: 'middle',
					//itemMarginTop: 0,
					//itemMarginBottom: 0,
					y: 50,
					x: 5,
					style: {
						fontSize: '10px',
					}
				},
				credits: {
					enabled: false
				}
			}
			,humidityChartOptions: {
				title: {
					text: "Humidity Diagram"
				},
				chart: {
        backgroundColor: '#282c34',
        type: 'area'
    }
				,xAxis: {
					title: {
						enabled: true,
						text: 'Date'
						,categories:[]
					}
					,startOnTick: true
					,endOnTick: true
					,showLastLabel: true
					,type: "category"
					,labels: {
						enabled: false
						,rotation:-45
					}
				}
				,yAxis: {
					title: {
						enabled: true
						,text: 'Humidity (%)'
					},
					min: -1.0,
					max: 1.0,
					startOnTick: true,
					endOnTick: true,
					showLastLabel: true

				}
				,tooltip: {
					split: false,
					shared:true,
					backgroundColor: 'black'
				}
				,series: []
				,legend: {
					enabled: true,
					align: 'right',
					layout: 'vertical',
					verticalAlign: 'middle',
					//itemMarginTop: 0,
					//itemMarginBottom: 0,
					y: 50,
					x: 5,
					style: {
						fontSize: '10px',
					}
				},
				credits: {
					enabled: false
				}
			}
			,precipitationChartOptions: {
				title: {
					text: "Precipitation Diagram"
				}
				,chart: {
        backgroundColor: '#282c34',
        type: 'area'
    }
				,xAxis: {
					title: {
						enabled: true,
						text: 'Date'
						,categories:[]
					}
					,startOnTick: true
					,endOnTick: true
					,showLastLabel: true
					,type: "category"
					,labels: {
						enabled: false
						,rotation:-45
					}
				}
				,yAxis: {
					title: {
						enabled: true
						,text: 'Precipitation (mm)'
					},
					min: -1.0,
					max: 1.0,
					startOnTick: true,
					endOnTick: true,
					showLastLabel: true

				}
				,tooltip: {
					split: false,
					shared:true,
					backgroundColor: 'black'
				}
				,series: []
				,legend: {
					enabled: true,
					align: 'right',
					layout: 'vertical',
					verticalAlign: 'middle',
					//itemMarginTop: 0,
					//itemMarginBottom: 0,
					y: 50,
					x: 5,
					style: {
						fontSize: '10px',
					}
				},
				credits: {
					enabled: false
				}
			}
			,windChartOptions: {
				title: {
					text: "Wind Diagram"
				}
				,chart: {
        backgroundColor: '#282c34',
        type: 'area'
    }
				,xAxis: {
					title: {
						enabled: true,
						text: 'Date'
						,categories:[]
					}
					,startOnTick: true
					,endOnTick: true
					,showLastLabel: true
					,type: "category"
					,labels: {
						enabled: false
						,rotation:-45
					}
				}
				,yAxis: {
					title: {
						enabled: true
						,text: 'Wind (km/h)'
					},
					min: -1.0,
					max: 1.0,
					startOnTick: true,
					endOnTick: true,
					showLastLabel: true

				}
				,tooltip: {
					split: false,
					shared:true,
					backgroundColor: 'black'
				}
				,series: []
				,legend: {
					enabled: true,
					align: 'right',
					layout: 'vertical',
					verticalAlign: 'middle',
					//itemMarginTop: 0,
					//itemMarginBottom: 0,
					y: 50,
					x: 5,
					style: {
						fontSize: '10px',
					}
				},
				credits: {
					enabled: false
				}
			}					
		}
	}
	,props: {}
	,methods:{
		onClose() {
        this.popoverShow = false
      },
		onChange(event) {
			if (event.flag === 'gr') {
			this.options2 = [
        {
          title: "en",
          flag: "gb"
        }
      ];	router.push("/el");	}	
        },
		selectLanguage: function(lang){
			if (lang === 'gr') {
			router.push("/el"); }
		}
		,setDataFrom: function(event) {
			var dateStart = new Date(event);
			dateStart.setHours(0);
			dateStart.setMinutes(0);
			dateStart.setSeconds(0);
			dateStart.setMilliseconds(0);
			this.from = dateStart;
			this.dateRange = {start: this.from , end: this.to};
			this.__updateWMSInfoForTileAndDateRange();
			//this.dateRange = event;
			
			//this.__updateWMSInfoForTileAndDateRange();
		}
		,setDataTo: function(event) {
			var dateEnd = new Date(event);
			dateEnd.setHours(23);
			dateEnd.setMinutes(59);
			dateEnd.setSeconds(59);
			dateEnd.setMilliseconds(999);
			this.to = dateEnd;
			this.dateRange = {start: this.from , end: this.to};
			this.__updateWMSInfoForTileAndDateRange('date');
			
		}
		,init: function() {
			//setting NDVI as active background layer type
            if (JSON.parse(localStorage.getItem('currentUser')) == null) {
				router.push("/login");
			} else {	
			this.to = this.$store.state.dateEnd;
			this.from = this.$store.state.dateStart;
			
			this.dateRange={
				start: this.$store.state.dateStart,
				end: this.$store.state.dateEnd
			};
			//setting the id for the current active tile
			this.activeTileID = this.tileList[0].id;
			this.activeTileInfo = new Array();

			this.currentWMSLayerIds = new Array();

			this.tileList.forEach( (item) => {
				this.activeTileInfo[item.id] = new Array();
				this.activeTileInfo[item.id] = {
					current_view_layer_type: "tci",
					layer_group: 0,
					wms_layer_id: null,
					figr_id: null
				}
			});
			//setting background
			this.bingLayer = this.$refs.olmap.addBingLayerToMap("ArYycvKoklvKbZOTjPv-9Ckak7Nb0hmARptRDBrRUbrPVC7QN6maRg1fZur0X7UC", "aerial");
			this.$refs.olmap.setMaxZoom(18);
			this.$refs.olmap.setMinZoom(7);
			//bring in the field polygons
			this.$refs.requests.fetchPolygons().then((response) => {
				this.genPolygons = response.body.data;
				let currentYear = new Date().getFullYear();
				if (response.body.data[currentYear] == null)
					return 0;
                let polygons = response.body.data[currentYear].features;
				Object.keys(polygons).forEach(key => {
						this.polygons.push(polygons[key].properties.id);
				});
				const minDate = new Date("1/1/"+currentYear);	minDate.setMonth(minDate.getMonth());	minDate.setDate(1);	const maxDate = new Date("12/31/"+currentYear);	maxDate.setMonth(maxDate.getMonth());	maxDate.setDate(31);
			
				this.min = minDate;
				this.max = maxDate; 
				this.polygonLayer = this.$refs.olmap.createGEOJSONLayer(response.body.data[currentYear], 2, false);
				this.$refs.olmap.setLayerStyle(this.polygonLayer, this.polygonStyles.Polygon);
				
				this.__updateWMSInfoForTileAndDateRange(true);
				this.$refs.olmap.addEventToLayer(this.polygonLayer, "polygonclick");	
			});
			
			this.olMap = this.$refs.olmap.getMap();	}				
		}
		,changeLayerType: function(type){
			if (type === "ndvi") {
				this.ndviShow = true;
			} else {
				this.ndviShow = false;
			}
			
			Object.keys(this.layerTypes).forEach((key)=>{
				this.layerTypes[key].active = key==type;
			});
			this.activeTileInfo[this.activeTileID]["current_view_layer_type"] = type;
			this.updateMapView(type);		
		}
		,createTemperatureTimeline: function(response) {
				//let tempData = [];
				let modelMinData = [];
				let modelMaxData = [];
				let categories = [];
				
				if (response.body.data != null) {
				if (response.body.data.weather_data != null) {
					response.body.data.weather_data.forEach( (val) => {
						let tmpDt = new Date(val.date);
						tmpDt = tmpDt.toDateString();
						modelMinData.push([tmpDt, val.min_temp_c, val.max_temp_c]);
						modelMaxData.push([tmpDt, val.max_temp_c])
						categories.push(tmpDt);
					});
				}}

				this.temperatureChartOptions.series = [];
				this.temperatureChartOptions.xAxis.categories = categories;

				this.temperatureChartOptions.series.push({
					name:"Max Temperature",
					data: modelMaxData, 
					showInLegend: false,
					color: "red",
					fillColor: "red",
					type:"spline"
				});

				this.temperatureChartOptions.series.push({
					name:"Min Temperature",
					data: modelMinData, 
					showInLegend: false,
					color: "#1a90ff",
					fillColor: "#1a90ff",
					type: 'spline',
				});

				this.temperatureChartOptions.yAxis.min = -10;
				this.temperatureChartOptions.yAxis.max = 40;
		}
		,createHumidityTimeline: function(response) {
				//let tempData = [];
				let modelMinData = [];
				let modelMaxData = [];
				let categories = [];
				if (response.body.data != null) {
				if (response.body.data.weather_data != null) {
					response.body.data.weather_data.forEach( (val) => {
						let tmpDt = new Date(val.date);
						tmpDt = tmpDt.toDateString();
						modelMinData.push([tmpDt, val.min_relative_humidity_percent, val.max_relative_humidity_percent]);
						modelMaxData.push([tmpDt, val.max_relative_humidity_percent])
						categories.push(tmpDt);
					});
				}}

				this.humidityChartOptions.series = [];
				this.humidityChartOptions.xAxis.categories = categories;

				this.humidityChartOptions.series.push({
					name:"Max Humidity",
					data: modelMaxData, 
					showInLegend: false,
					color: "red",
					fillColor: "red",
					type:"spline"
				});

				this.humidityChartOptions.series.push({
					name:"Min Humidity",
					data: modelMinData, 
					showInLegend: false,
					color: "#1a90ff",
					fillColor: "#1a90ff",
					type: 'spline',
				});

				this.humidityChartOptions.yAxis.min = 0;
				this.humidityChartOptions.yAxis.max = 100;
		}
		,createPrecipitationTimeline: function(response) {
				//let tempData = [];
				let precData = [];
				let categories = [];
				if (response.body.data != null) {
				if (response.body.data.weather_data != null) {
					response.body.data.weather_data.forEach( (val) => {
						let tmpDt = new Date(val.date);
						tmpDt = tmpDt.toDateString();
						precData.push([tmpDt, val.precipitation_mm]);
						categories.push(tmpDt);
					});
				}}

				this.precipitationChartOptions.series = [];
				this.precipitationChartOptions.xAxis.categories = categories;

				this.precipitationChartOptions.series.push({
					name:"Precipitation",
					data: precData, 
					showInLegend: false,
					color: "#1a90ff",
					fillColor: "#1a90ff",
                    type: 'column'
				});

				this.precipitationChartOptions.yAxis.min = 0;
				this.precipitationChartOptions.yAxis.max = 100;
		}
		,createWindTimeline: function(response) {
				//let tempData = [];
				let modelMinData = [];
				let modelMaxData = [];
				let categories = [];
				if (response.body.data != null) {
				if (response.body.data.weather_data != null) {
					response.body.data.weather_data.forEach( (val) => {
						let tmpDt = new Date(val.date);
						tmpDt = tmpDt.toDateString();
						modelMinData.push([tmpDt, val.min_wind_velocity_kmh, val.max_wind_velocity_kmh]);
						modelMaxData.push([tmpDt, val.max_wind_velocity_kmh])
						categories.push(tmpDt);
					});
				}}

				this.windChartOptions.series = [];
				this.windChartOptions.xAxis.categories = categories;

				this.windChartOptions.series.push({
					name:"Max Wind Velocity",
					data: modelMaxData, 
					showInLegend: false,
					color: "red",
					fillColor: "red",
					type:"spline"
				});

				this.windChartOptions.series.push({
					name:"Min Wind Velocity",
					data: modelMinData, 
					showInLegend: false,
					color: "#1a90ff",
					fillColor: "#1a90ff",
					type: 'spline',
				});

				this.windChartOptions.yAxis.min = 0;
				this.windChartOptions.yAxis.max = 75;
		}
		,showNDVITimeline: function(event , str) {
			let polygonId;
			
			if (str !== 'nav') {
			if (typeof(event) == 'string') {
                polygonId = event;
				this.polygonid = Number(polygonId);
			} else {
				polygonId = event.get("id");
				this.polygonid = Number(polygonId);
			}
				Object.keys(this.polygons).forEach(key => {
				if (this.polygons[key] === this.polygonid) {
						this.polygon = Number(key);
					}
				});

				if (this.polygons[this.polygon + 1]) { 
				this.disablePrev = false;
				this.disableNext = false;
			} else {
				this.disableNext = true;
			}

			if (!this.polygons[this.polygon - 1]) {
				this.disablePrev = true;
			} else {
				this.disablePrev = false;
			}

			} else {
				polygonId = event;
				this.polygonId = Number(polygonId);
			}
			
			this.$refs.requests.fetchPolygonNDVIAndDailyWeatherData(polygonId,
			this.dateRange.start, this.dateRange.end).then( (response) => {
				let ndviData = [];
				let categories = [];
				if (response.body.data.ndvi.ndvi_data != null) {
					response.body.data.ndvi.ndvi_data.forEach( (val) => {
						let tmpDt = new Date(val.acquisition_ts);
						tmpDt = tmpDt.toDateString();
						tmpDt = tmpDt.substring(3, tmpDt.length);
						ndviData.push([tmpDt, val.mean]);
						categories.push(tmpDt);
						this.createWindTimeline(response);
						this.createTemperatureTimeline(response);
						this.createHumidityTimeline(response);
						this.createPrecipitationTimeline(response);
					});
				}
				
				let a = 0.32984322;
                let b = 0.34031357;
                for(let i = 1; i < ndviData.length-1; i++) {
					ndviData[i][1] = ndviData[i-1][1]*a + ndviData[i][1]*b + ndviData[i+1][1]*a;
					//ndviData[i][1] = Number(ndviData[i][1].toFixed(3));
				}
				
				this.ndviChartOptions.series = [];
				this.ndviChartOptions.xAxis.categories = categories;
				
				this.ndviChartOptions.series.push({
					name:"Field NDVI",
					data: ndviData, 
					showInLegend: false,
					color: "limegreen",
					fillColor: "limegreen",
					type: 'spline',
				});

				this.ndviChartOptions.yAxis.min = 0;
				this.ndviChartOptions.yAxis.max = 1.0;
                this.isParcelSidebarOpen = true;
				this.ndviPolygonData = response.body.data.ndvi.info;
				this.ndviPolygonData["season"] = this.year;
				
				
			});
		}
		,setGrowingSeason: function(event) {
			Object.keys(this.years).forEach((key)=>{
				if (event.target.value == key) {
					this.year = this.years[key].name;
				}
			});
			let dateEnd = new Date("12/31/"+this.year);
			dateEnd.setHours(23);
			dateEnd.setMinutes(59);
			dateEnd.setSeconds(59);
			dateEnd.setMilliseconds(999);

			let dateStart  = new Date("01/01/"+this.year);
			dateStart.setHours(0);
			dateStart.setMinutes(0);
			dateStart.setSeconds(0);
			dateStart.setMilliseconds(0);

			this.dateRange={
				start: dateStart,
				end: dateEnd
			};

			const minDate = new Date("1/1/"+this.year);	minDate.setMonth(minDate.getMonth());	minDate.setDate(1);	const maxDate = new Date("12/31/"+this.year);	maxDate.setMonth(maxDate.getMonth());	maxDate.setDate(31);
			
			this.min = minDate;
			this.max = maxDate;
			this.from = dateStart;
			this.to = dateEnd;
			this.__updateWMSInfoForTileAndDateRange("date");

		}
		,setActiveTile: function (event) {
			this.activeTileID = event.target.value;
			Object.keys(this.layerTypes).forEach((key)=>{
				this.layerTypes[key].active = this.activeTileInfo[this.activeTileID]["current_view_layer_type"] == key;
			});
			if (this.currentWMSLayerGroup[this.activeTileID]) {
				this.$refs.olmap.fitToExtent(this.currentWMSLayerGroup[this.activeTileID][0]["extent"]);}
			else {
				this.$refs.wmsmodal.setText("No data available. Please specify another date range.");
			}
		}
		,updateMapView: function(type) {
			this.currentWMSLayerGroup[this.activeTileID].forEach( (date, key) => {
				if (isNaN(type)) {
				if (type == date.acquisition_ts) {
					let year = String(type.slice(type.length - 4));
					this.year = year;
					console.log(this.genPolygons);
					let polygons = this.genPolygons[year].features;
						Object.keys(polygons).forEach(key => {
							this.polygons.push(polygons[key].properties.id);
					});
					this.$refs.olmap.removeLayer(this.polygonLayer);
					this.polygonLayer = this.$refs.olmap.createGEOJSONLayer(this.genPolygons[year], 2, false);
					this.$refs.olmap.setLayerStyle(this.polygonLayer, this.polygonStyles.Polygon);
					this.$refs.olmap.addEventToLayer(this.polygonLayer, "polygonclick");
					type = key;
					this.date = type;
					this.activeTileInfo[this.activeTileID]["layer_group"] = type;
				}} else {
					if (type == key) {
						this.currentDate = type;
						let year = String(date.acquisition_ts.slice(date.acquisition_ts.length - 4));
						this.year = year;
						this.polygons = [];
						if(this.genPolygons[year]) {
						let polygons = this.genPolygons[year].features;
							Object.keys(polygons).forEach(key => {
								this.polygons.push(polygons[key].properties.id);
						});
						this.polygon = -1;
						this.$refs.olmap.removeLayer(this.polygonLayer);
					this.polygonLayer = this.$refs.olmap.createGEOJSONLayer(this.genPolygons[year], 2, false);
					this.$refs.olmap.setLayerStyle(this.polygonLayer, this.polygonStyles.Polygon);
					this.$refs.olmap.addEventToLayer(this.polygonLayer, "polygonclick");
					}
					}
				}
			})
			
			if (type === 'tci' || type === 'cir' || type === 'ndvi' || type === 'bing') {
			this.type = type;
			}
			this.$refs.olmap.setLayerVisible(this.activeTileInfo[this.activeTileID]["wms_layer_id"], false);
			if (this.previousType == 'ndvi') {
			this.$refs.olmap.removeLayer(this.activeTileInfo[this.activeTileID]["wms_layer_id"]);
			}
			let currentGroup = this.activeTileInfo[this.activeTileID]["layer_group"];
			let currentLayerType = this.activeTileInfo[this.activeTileID]["current_view_layer_type"];
			this.activeTileInfo[this.activeTileID]["wms_layer_id"] = this.currentWMSLayerIds[this.activeTileID][currentGroup]["ol_ids"][currentLayerType];
			if (this.type == 'ndvi') {
				this.activeTileInfo[this.activeTileID]["wms_layer_id"]=this.$refs.olmap.maskWMSByVector(this.activeTileInfo[this.activeTileID]["wms_layer_id"], this.polygonLayer);
			}
			this.$refs.olmap.setLayerVisible(this.activeTileInfo[this.activeTileID]["wms_layer_id"], true);
			if(this.activeTileInfo[this.activeTileID]["figr_id"] != this.currentWMSLayerIds[this.activeTileID][currentGroup]["figr_id"]) {
				this.activeTileInfo[this.activeTileID]["figr_id"] = this.currentWMSLayerIds[this.activeTileID][currentGroup]["figr_id"];
			}	
			this.previousType = this.type;
		}
		,__updateWMSInfoForTileAndDateRange: function (flag) {
			//subtracting the timezone offset to send the dates to 00:00:00 - 23:59:59
			let tmpDateStart = new Date(this.dateRange.start.getTime() - 60000*this.dateRange.start.getTimezoneOffset());
			let tmpDateEnd = new Date(this.dateRange.end.getTime() - 60000*this.dateRange.end.getTimezoneOffset());

			if (flag=="date") {
			this.$refs.olmap.removeLayer(this.polygonLayer);
			this.polygonLayer = this.$refs.olmap.createGEOJSONLayer(this.genPolygons[this.to.getUTCFullYear()], 2, false);
			this.$refs.olmap.setLayerStyle(this.polygonLayer, this.polygonStyles.Polygon);
			this.$refs.olmap.addEventToLayer(this.polygonLayer, "polygonclick");}
		
			this.$refs.requests.fetchByTileAndDateRangeWMSInfo(this.$store.state.tileList, 
			tmpDateStart.toISOString(), tmpDateEnd.toISOString()).then( (response) => {
				if (!response.body.data) {
					this.$refs.wmsmodal.setText("No data available. Please specify another date range.");
					this.showWMSWarningModal = true;
					return 0;
				}
				this.currentWMSLayerGroup = response.body.data;
				let curImage = response.body.data[this.activeTileID][0].acquisition_ts;
				let year = String(curImage.slice(curImage.length - 4));
				this.year = year;
				
				if (this.currentWMSLayerGroup == null)
					return 0;				

				Object.keys(this.currentWMSLayerGroup).forEach( (tileID) => {
					if (this.currentWMSLayerIds[tileID] != null)
						this.currentWMSLayerIds[tileID].forEach((layerGroup) => {
							Object.keys(layerGroup).forEach((key) =>{
								if (typeof layerGroup[key] === 'object' && layerGroup[key] !== null)
									Object.keys(layerGroup[key]).forEach((layerID) => {
										this.$refs.olmap.removeLayer(layerGroup[key][layerID]);	
									});
							});
						});				
                    if (this.type === 'ndvi') {
						this.$refs.olmap.removeLayer(this.activeTileInfo[tileID]["wms_layer_id"]);
					}
					this.currentWMSLayerIds[tileID] = new Array();
					let allowVisible = true;
					this.currentWMSLayerGroup[tileID].forEach( (layerInfo) => {
						let url = this.$store.state.wmsBaseURL + layerInfo["relative_base_url"];
						let tmpObj = {};
						tmpObj["figr_id"] = layerInfo["figr_id"];
						tmpObj["ol_ids"] = {};
						Object.keys(this.layerTypes).forEach(type => {
							
							let params = {
								LAYERS: layerInfo[type],
								TILED: true
							}

						
                           
                            tmpObj["ol_ids"][type] = this.$refs.olmap.createWMSLayer(url, params,1, false, layerInfo["extent"]);
                            
							
							if (allowVisible && this.activeTileInfo[tileID]["current_view_layer_type"] == type) {
								this.activeTileInfo[tileID]["wms_layer_id"] = tmpObj["ol_ids"][type];
								if (type === 'ndvi') {
									this.activeTileInfo[tileID]["wms_layer_id"] = this.$refs.olmap.maskWMSByVector(tmpObj["ol_ids"][type], this.polygonLayer);
								}
								this.$refs.olmap.setLayerVisible(this.activeTileInfo[tileID]["wms_layer_id"], true);
								this.activeTileInfo[tileID]["layer_group"] = 0;
								this.activeTileInfo[tileID]["figr_id"] = layerInfo["figr_id"];
								allowVisible = false;
							}
						});
						this.currentWMSLayerIds[tileID].push(tmpObj);	
					});
				});
				
			});
		} , 
		logout: function () {
			localStorage.removeItem('currentUser');
			router.push("/login");
		},
		search: function() {
			const { searchInput } = this;
			if (searchInput) {
				let extent;
				if (this.$refs.olmap.getFeature(this.polygonLayer, searchInput)) {
				extent = this.$refs.olmap.getFeature(this.polygonLayer, searchInput).getGeometry().getExtent();}
				else {
					this.$refs.wmsmodal.setText("No data available. Please specify another field id.");
					this.showWMSWarningModal = true;
				}
				if (extent) {
				this.$refs.olmap.fitToExtent(extent);
				this.showNDVITimeline(searchInput, "search");
				}
			}
		},
		next: function() {
			let extent;
			if (this.polygons[this.polygon + 1]) {
				if (this.$refs.olmap.getFeature(this.polygonLayer, this.polygons[this.polygon + 1])) {
				extent = this.$refs.olmap.getFeature(this.polygonLayer, this.polygons[this.polygon + 1]).getGeometry().getExtent();
				}
				else {
					this.disableNext = true;
					this.$refs.wmsmodal.setText("No data available. Please specify another field id.");
					this.showWMSWarningModal = true;
				}
				if (extent) {
				this.$refs.olmap.fitToExtent(extent);
				this.showNDVITimeline(this.polygons[this.polygon + 1] , "nav");
				this.polygon = this.polygon + 1;}
				if (this.polygons[this.polygon + 1] == undefined) {
					this.disableNext = true;
				}
				
				if (this.polygons[this.polygon - 1] == undefined) {
				this.disablePrev = true;
			} else {
				this.disablePrev = false;
			}}
		},
		previous: function() {

			this.disableNext = false;
			if (this.polygons[this.polygon - 1]) {
				
			let extent;
				if (this.$refs.olmap.getFeature(this.polygonLayer, this.polygons[this.polygon - 1])) {
				extent = this.$refs.olmap.getFeature(this.polygonLayer, this.polygons[this.polygon - 1]).getGeometry().getExtent();
				
				}
				else {
					this.$refs.wmsmodal.setText("No data available. Please specify another field id.");
					this.showWMSWarningModal = true;
				}
				if (extent) {
				this.$refs.olmap.fitToExtent(extent);
				this.showNDVITimeline(this.polygons[this.polygon - 1], "nav");}
				
				this.polygon = this.polygon - 1;
				}
				if (this.polygons[this.polygon - 1] == undefined) {
				this.disablePrev = true;
				}
		},
		np: function () {   
          window.open("https://www.neuropublic.gr/en/", "_blank");    
        },
		highlevel: function () {
			this.$refs.olmap.removeLayer(this.activeTileInfo[this.activeTileID]["wms_layer_id"]);
			router.push("/highlevel");
		},
		damage: function () {
			this.$refs.olmap.removeLayer(this.activeTileInfo[this.activeTileID]["wms_layer_id"]);
			router.push("/damage");
		},
		showEshape: function() {
			this.showEs = !this.showEs;
		}
	},mounted:function() {
		this.init();
	},filters: {
        Round(value) {
              return Number((value).toFixed(0));
        },	
}
}
</script>

<style>
#sidebar-right {
	border-radius: 15px;
}

.foot-left {
	background-color: white !important;
	width: 320px !important; 
	border-radius: 5px !important;   
}

.esButton:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn_wrap i {
    opacity: 0;
    font-size: 28px;
    color: white;
    will-change: transform;
    -webkit-transform: scale(.1);
            transform: scale(.1);
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.btn_wrap {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow: hidden;
    cursor: pointer;
    width: 140px;
    height: 42px;
    background-color: #282c34;
    padding: 0 18px;
    will-change: transform;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.btn_wrap:hover {
    /* transition-delay: .4s; */
    -webkit-transform: scale(1.1);
            transform: scale(1.1)
}

.btn_wrap span {
    position: absolute;
    z-index: 99;
    width: 240px;
    height: 72px;
    font-size: 17px;
    text-align: center;
    line-height: 70px;
    letter-spacing: 2px;
    color: white;
    background-color: #282c34;
    padding: 0 18px;
    -webkit-transition: all 1.2s ease;
    transition: all 1.2s ease;
}

.btn_wrap span i {
    z-index: 99;
    color: white;
	opacity: 1;-webkit-transform: scale(1);
            transform: scale(1);
}

.btn_wrap .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 240px;
    height: 64px;
    border-radius: 80px;
}

.btn_wrap .container i:nth-of-type(1) {
            -webkit-transition-delay: 1.1s;
                    transition-delay: 1.1s;
}

.btn_wrap .container i:nth-of-type(2) {
            -webkit-transition-delay: .9s;
                    transition-delay: .9s;
}

.btn_wrap .container i:nth-of-type(3) {
            -webkit-transition-delay: .7s;
                    transition-delay: .7s;
}

.btn_wrap .container i:nth-of-type(4) {
            -webkit-transition-delay: .4s;
                    transition-delay: .4s;
}

.btn_wrap:hover span {
    -webkit-transition-delay: .25s;
            transition-delay: .25s;
    -webkit-transform: translateX(-280px);
            transform: translateX(-280px)
}

.btn_wrap:hover i {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
}


.btn_wrap .dr {
position: absolute;
  bottom: 16px; 
  right: 16px;
  width:100px;
}

.hooper {
	opacity: 0.9;
	width: 30%;
	background: transparent;
	position: absolute;
    bottom: 0;
    left: 22%;
	height: unset !important;
}

.hooper button {
	top: 40px;
	border-radius: 5px;
    background: #343a40;
    border: 2px solid white;
}

.hooper li {
	background: #343a40;
	color: white;
	height: 50px;
	border: 1px solid white !important;
	cursor: pointer;
	text-align: center;
}

.hooper li span{

	position: relative;
    top: 10px;
	font-size: 13px;
}

.hooper .active {
	background-color: rgb(62, 180, 137) !important;
}

.hooper svg {
	fill: white;

}

</style>
