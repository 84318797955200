<template>
  <div class="vue-tempalte">
   
          <router-view></router-view>
        
  </div>
</template>

<script>
export default {
  methods: {
  checkIfIsLogged () {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style>
@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu"),
   url(./assets/fonts/Ubuntu-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Ubuntu-Light";
  src: local("Ubuntu-Light"),
   url(./assets/fonts/Ubuntu/Ubuntu-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Ubuntu-Medium";
  src: local("Ubuntu-Medium"),
   url(./assets/fonts/Ubuntu/Ubuntu-Medium.ttf) format("truetype");
}
</style>